<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="saving"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>

<form *ngIf="consentForm" class="user" [formGroup]="consentForm" (ngSubmit)="saveBusinessConsent()">
  <mat-card #Consent class="m-3">
    <mat-card-header>
      <mat-card-title class="mt-3">Your Consent</mat-card-title>
      <mat-card-subtitle>Welcome to ADVIBE Pty Ltd. Before you embark on using our services, we kindly request your consent to the terms and conditions outlined below. Please take the time to peruse this document attentively. By engaging with our Platform, you signify your acceptance and adherence to the ensuing terms.
        Acceptance of Terms: </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="m-3">
      <div class="row">
        <div class="col-md-12">
          <div class="w-80" #ConsentContainer [innerHtml]="dataConsent | safeHtml"></div>
        </div>
        <div class="col-md-12 d-flex flex-column align-items-center">
          <h2 id="Consent">Do you consent?</h2>
          <mat-radio-group
            class="bigRadio"
            aria-labelledby="Consent"
            formControlName="InformedDataConsent"
          >
            <mat-radio-button class="example-radio-button m-3" [value]="true">
              Yes
            </mat-radio-button>
            <mat-radio-button class="example-radio-button m-3" [value]="false">
              No
            </mat-radio-button>
          </mat-radio-group>
          <mat-error
            *ngIf="
              consentForm.controls['InformedDataConsent'].errors ||
              (!business?.InformedDataConsent &&
                !consentForm.controls['InformedDataConsent'].value)
            "
            >In order for you to use the platform we need your consent.</mat-error
          >
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="text-center">
    <mat-card-content class="m-3">
      <div class="row">
        <div class="col-md-8 d-flex flex-column justify-content-center">
          <h2>Signature</h2>
        </div>
        <div class="col-md-4 d-flex flex-column align-items-center">
          <img #signatureElement />
          <button
            mat-raised-button
            color="accent"
            type="button"
            (click)="openDialog()"
          >
            Sign
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <p *ngIf="consentForm.invalid" class="text-error">
    Please ensure that you read and consent to the above
  </p>
  <mat-toolbar class="d-flex justify-content-between">
    <button type="button" mat-button (click)="back()">Back</button>
    <!-- <div class="d-flex"> -->
      <div *ngIf="business?.Document?.source">
        <button
          class="mr-3"
          mat-flat-button
          type="button"
          *ngIf="business && business?.Document?.name"
          (click)="download()"
        >
          <fa-icon [icon]="faDownload"></fa-icon>Download Saved Consent
        </button>
      </div>
      <!-- <button *ngIf="signed" mat-button type="button" (click)="generatePDF('view')"><i class="fa fa-download fa-2x" aria-hidden="true"></i>&nbsp;Preview PDF</button> -->
      <button [disabled]="!signed" mat-raised-button type="submit" color="primary">
        {{ saving ? "Saving" : "Save Consent" }}
      </button>
    <!-- </div> -->
  </mat-toolbar>
</form>

