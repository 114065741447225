
<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="saving || !profile">
    <div>
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>
   <!-- <mat-stepper linear #stepper
    [orientation]="(stepperOrientation | async)!"> -->
    <!-- <mat-step state="done" *ngIf="profileForm" [completed]="profileForm?.valid" [stepControl]="profileForm" label="Profile"> -->
        <mat-card class="m-3">
            <mat-card-content>
                <h1>User Profile</h1>
                <form *ngIf="profileForm" class="user" [formGroup]="profileForm" (ngSubmit)="saveProfile()">
                    <div class="d-flex row p-3">
                  <div class="col-md-6 d-flex flex-column">
                      <mat-form-field appearance="outline">
                          <mat-label>Name</mat-label>
                          <input matInput placeholder="Name" name="name" formControlName="Name">
                          <mat-error *ngIf="profileForm.controls.Name.invalid">Name is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Surname</mat-label>
                          <input matInput placeholder="Surname" formControlName="Surname">
                          <mat-error *ngIf="profileForm.controls.Surname.invalid">Surname is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field *ngIf="!profile.Id" appearance="outline">
                          <mat-label>ID</mat-label>
                          <input matInput placeholder="Id" formControlName="ID">
                          <mat-error *ngIf="profileForm.controls.ID.invalid">ID is Required</mat-error>
                      </mat-form-field>
                      
                  </div>
                  <div class="col-md-6 d-flex flex-column">
                      <mat-form-field appearance="outline">
                          <mat-label>Address</mat-label>
                          <input matInput placeholder="Address" formControlName="Address">
                          <mat-error *ngIf="profileForm.controls.Address.invalid">Address is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Mobile</mat-label>
                          <input matInput placeholder="Mobile" formControlName="Mobile">
                          <mat-error *ngIf="profileForm.controls.Mobile.invalid">Mobile is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Email</mat-label>
                          <input matInput placeholder="Email" formControlName="Email">
                          <mat-error *ngIf="profileForm.controls.Email.invalid">Email is Required</mat-error>
                      </mat-form-field>
                  </div>
                </div>
                <mat-card-footer class="d-flex p-3">
                    <button type="submit" [disabled]="!profileForm.valid || saving" mat-raised-button color="primary">
                      Save
                    </button>
                  </mat-card-footer>
                </form>
            </mat-card-content>
          </mat-card>
    <!-- </mat-step> -->
    <!-- <mat-step *ngIf="medicalForm" [completed]="medicalForm.valid" [stepControl]="medicalForm" label="Medical Aid">
        <mat-card class="m-3">
            <mat-card-content>
                <form class="user" [formGroup]="medicalForm" (ngSubmit)="saveMedicalDetails()">
                <div class="row d-flex p-3">
                
                  <div class="col-md-6 d-flex flex-column">
                      <mat-form-field appearance="outline">
                          <mat-label>Name</mat-label>
                          <input matInput placeholder="MedicalAidName" formControlName="MedicalAidName">
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Number</mat-label>
                          <input matInput placeholder="MedicalAidNumber" formControlName="MedicalAidNumber">
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Contact Person</mat-label>
                          <input matInput placeholder="MedicalAidMainMember" formControlName="MedicalAidMainMember">
                      </mat-form-field>
                  </div>
                  <div class="col-md-6 d-flex flex-column">
                      <mat-form-field appearance="outline">
                          <mat-label>Problems</mat-label>
                          <input matInput placeholder="HealthProblems" formControlName="HealthProblems">
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Notes</mat-label>
                          <input matInput placeholder="Notes" formControlName="Notes">
                      </mat-form-field>
                  </div>
                </div>
                <mat-toolbar class="d-flex justify-content-between">
                    <button type="button" mat-button (click)="back()">
                        Back
                      </button>
                    <button type="submit" [disabled]="!medicalForm.valid || saving" mat-raised-button color="primary">
                        {{ medicalForm.dirty ? 'Save' : 'Next' }}
                    </button>
                  </mat-toolbar>
                </form>
            </mat-card-content>
          </mat-card>
    </mat-step> -->
    <!-- <mat-step *ngIf="profile" [completed]="profile.Document && profile.Document.name" [stepControl]="thirdFormGroup" label="Consent">
        <app-student-consent-form (emitBack)="back()" [student]="profile" (emitStudent)="saveParentConsent($event)"></app-student-consent-form>
    </mat-step> -->
  <!-- </mat-stepper> -->