import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/auth/auth.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  subscriptionDetails;
  faCheckCircle = faCheckCircle;
  paymentConfirmed;
  constructor(private authService: AuthService,private http: HttpClient, private paymentService: PaymentService) { }

  ngOnInit(): void {
    this.authService.$User.subscribe(async (res) => {
      if (res) {
        this.paymentService.subscriptionDetails.next(res.get('Subscription'));
        this.paymentConfirmed = res.get('PaymentConfirmed')
      }
    });
    this.paymentService.subscriptionDetails$.subscribe(res => {
      console.log(res)
      this.subscriptionDetails = res;
    })
  }

}
