import { Injectable } from '@angular/core';
import { Parent } from 'backend/interfaces/parent.interface';
import { BehaviorSubject } from 'rxjs';
import * as Parse from 'parse';

import { Student } from 'backend/interfaces/student.interface';
import { Business } from 'backend/interfaces/business.interface';
import { Ad } from 'backend/interfaces/ad.interface';

@Injectable({
  providedIn: 'root'
})
export class BusinessDataService {
  parent = new BehaviorSubject<Business | null>(null);
  parent$ = this.parent.asObservable();
  business = new BehaviorSubject<Business | null>(null);
  business$ = this.business.asObservable();
  businesses = new BehaviorSubject<Business[] | null>(null);
  businesses$ = this.businesses.asObservable();
  ads = new BehaviorSubject<Ad[] | null>(null);
  ads$ = this.ads.asObservable();
  activeAd = new BehaviorSubject<Ad | null>(null);
  activeAd$ = this.activeAd.asObservable();
  favorites = new BehaviorSubject<Business[] | null>(null);
  favorites$ = this.favorites.asObservable();
  constructor() {
  }

  getParentProfile(email: string): Promise<Parent> {
    return Parse.Cloud.run('getParentByEmail', { email })
  }

  getBusinessProfile(registrationNumber: string): Promise<Business> {
    return Parse.Cloud.run('getBusinessByRegNumber', { registrationNumber })
  }

  getBusinessesByUserId(id: string): Promise<Business[]> {
    return Parse.Cloud.run('getBusinessesByUserId', { id })
  }

  saveAd(ad: Ad): Promise<any>{
    return Parse.Cloud.run('saveAd', { ad })
  }

  getAdsByBusinessId(id: string): Promise<any>{
    return Parse.Cloud.run('getAdsByBusinessId', { id }).then(res => this.ads.next(res))
  }

  getAdsByCategoryId(id: string): Promise<any>{
    return Parse.Cloud.run('getAdsByCategoryId', { id })
  }

  deleteAd(id: string): Promise<any>{
    return Parse.Cloud.run('deleteAd', { id });
  }

  getUserFavorites(favoritesArray: Business[]): Promise<any>{
    return Parse.Cloud.run('getUserFavorites', { favoritesArray })
  }
}
