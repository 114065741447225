import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {
  @Input('target') target; 
  constructor() { }
  counter = 0;
  ngOnInit(): void {
    for(let i = 0; i < this.target; i++){
        this.counter = i;
    }
  }

}
