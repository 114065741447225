<div class="container-fluid about">
  <div class="row d-flex justify-content-start">
      <div class="col-md-5 m-3 p-3 border-primary bg-50perc-white">
        <h1>Welcome to ADVIBE</h1>

        <p>
          You are at the premier destination for businesses seeking
          unparalleled visibility and engagement across a vast array of
          industries. Our platform is designed to revolutionize the way
          businesses connect with their audience, providing a dynamic and
          intuitive space where brands can thrive and followers can discover
          their next favorite venture.
        </p>
      </div>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-md-5 m-3 p-3 border-primary bg-50perc-white">
    <h1>We understand</h1>
        <p>
          At ADVIBE, we understand the importance of being seen in today's
          digital landscape. That's why we've curated a comprehensive network
          spanning <span [countUp]="categoryCount">0</span> diverse categories,
          ensuring that businesses of all types have the opportunity to shine.
          Whether you're a budding startup or an established enterprise, our
          platform offers the perfect stage to showcase your offerings and
          captivate your target audience.
        </p>

        </div>
  </div>
  <div class="row d-flex justify-content-end">

    <div class="col-md-5 m-3 p-3 border-primary bg-50perc-white">
        <h1>We're different</h1>
        <p>
          What sets ADVIBE apart is our unwavering commitment to fostering
          meaningful connections between businesses and their followers. Our
          user- friendly interface makes it easy for followers to discover new
          brands and engage with their favorite businesses, while our robust
          suite of tools empowers businesses to elevate their online presence
          and drive lasting success.
        </p>

        </div>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-md-5 m-3 p-3 border-primary bg-50perc-white">
      <h1>We believe in the power of collaboration and mutual support</h1>
      <p>
        ADVIBE is more than just a marketplace—it's a community. We believe
        in the power of collaboration and mutual support, which is why we
        encourage businesses to join forces, share insights, and grow together.
        Whether you're looking to expand your reach, forge new partnerships, or
        simply connect with like-minded entrepreneurs, you'll find a welcoming
        home here at ADVIBE.
      </p>
    </div>
  </div>
  <div class="row d-flex justify-content-start">
    <div class="col-md-5 m-3 p-3 border-primary bg-50perc-white">

      <h1>Join us</h1>
      <p>
        So, whether you're a business looking to expand your horizons or a
        follower eager to discover the next big thing, we invite you to join us
        on this exciting journey. Together, we'll redefine what it means to be
        seen and found online. Welcome to ADVIBE—where visibility meets
        opportunity.
      </p>
    </div>
  </div>
</div>
