import { Component, OnInit } from '@angular/core';
import * as Parse from 'parse';
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  constructor() { }
  loading = false;
  terms;
  async ngOnInit(): Promise<void> {
    this.loading = true;
    Parse.Cloud.run('getTerms').then(async res => {
      this.terms = await res.get('termsAndConditions')
      this.loading = false;
    });
  }

}
