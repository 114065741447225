import { Component, OnInit } from '@angular/core';
import * as Parse from 'parse';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor() { }
  loading = false;
  privacy;
  async ngOnInit(): Promise<void> {
    this.loading = true;
    Parse.Cloud.run('getPrivacyPolicy').then(async res => {
      this.privacy = await res.get('privacyPolicy')
      this.loading = false;
    });
  }

}
