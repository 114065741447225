import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GroupService } from 'src/app/admin/services/group.service';
import { SideNavService } from 'src/app/admin/services/side-nav.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-search-group',
  templateUrl: './search-group.component.html',
  styleUrls: ['./search-group.component.scss']
})
export class SearchGroupComponent implements OnInit {

  loading = false;
  searchGroupForm: UntypedFormGroup;
  noResults = false;
  constructor(private auth: AuthService, private fb: UntypedFormBuilder, private groupService: GroupService, private sideNavService: SideNavService, private router: Router) { }
  isAdmin = false;
  ngOnInit(): void {
      this.auth.$User.subscribe(res => this.isAdmin = res.role === 'admin')
      this.searchGroupForm = this.fb.group(
        {
          name: ['', Validators.required]
      });
  }
  search(){
    this.noResults = false;
    this.loading = true;
    this.groupService.activeSearch.next(this.searchGroupForm.value.name);
    this.groupService.searchGroup(this.searchGroupForm.value).then(res => {
      if(res.length > 0){
        this.groupService.groups.next(res);
        this.sideNavService.close();
        if(this.router.url.includes('admin')){
          this.router.navigate(['admin','categories']);
        }else{
          this.router.navigate(['user','categories']);
        }
      } else {
        this.noResults = true;
      }
      this.loading = false;
    })
  }
}
