<h1 class="text-center m-3">Thank you for signing up!</h1>

<div class="row justify-content-center">
<div class="mat-card col-md-4 form-check relative" *ngIf="subscriptionDetails.amount === '1000'">
<h2 class="m-3">Kickstart 101</h2>
<p>Limited ads</p>
<p>R1000 / $56</p>
<p>
  Monthly This will include 10 ads being posted on your selected category.
</p>
<p><fa-icon [icon]="faCheckCircle"></fa-icon>{{paymentConfirmed ? 'Confirmed' : 'Pending confirmation'}}</p>
</div>
<div class="mat-card col-md-4 form-check relative" *ngIf="subscriptionDetails.amount === '2250'">
<h2 class="m-3">VibeUp 101</h2>
<p>Unlimited ads</p>
<p>R2250.00 / $ 125.00</p>
<p>
  Monthly This will include UNLIMITED ads being posted on your selected
  category.
</p>
<p> <fa-icon [icon]="faCheckCircle"></fa-icon>{{paymentConfirmed ? 'Confirmed' : 'Pending confirmation'}}</p>
</div>
</div>
<div class="row justify-content-center mt-3">
    <button class="text-white" routerLink="../dashboard" mat-raised-button color="primary">Continue to my dashboard</button>
</div>