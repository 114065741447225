import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  FormData: FormGroup;
  loading = false;
  constructor(private builder: FormBuilder, private dataService: DataService, private _snackBar: MatSnackBar) { 
  }
  
  ngOnInit() {
  this.FormData = this.builder.group({
  name: new FormControl('', [Validators.required]),
  number: new FormControl('', [Validators.required]),
  email: new FormControl('', [Validators.required, Validators.compose([Validators.required, Validators.email])]),
  message: new FormControl('', [Validators.required])
  })
  }

  onSubmit() {
    this.loading=true;
    this.dataService.contactUsEmail(this.FormData.value).then(res => {
      this.openSnackBar('Message sent. Thank you.');
      this.loading = false;
    })

  }


  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

}
