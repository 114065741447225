import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicComponent } from './public.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule, momentAdapterFactory } from '../shared/shared.module';
import { AdminRoutingModule } from './public-site.routing.module';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { NgxTimelineModule } from '@frxjs/ngx-timeline';
import { CountUpModule } from 'ngx-countup';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';



@NgModule({
  declarations: [
    PublicComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, AdminRoutingModule, FontAwesomeModule, SharedModule, MatTableModule, MatCheckboxModule, MatSidenavModule, MatFormFieldModule, MatToolbarModule, MatIconModule, MatButtonModule, MatMenuModule, MatCardModule, MatProgressSpinnerModule, MatTabsModule, MatPaginatorModule, MatInputModule, MatTableModule, CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }), NgxTimelineModule, CountUpModule, MatProgressBarModule],
})
export class PublicSiteModule { }
