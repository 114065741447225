<h1>Notifications</h1>
<form [formGroup]="notificationsForm" (ngSubmit)="savePreferences()">
<h2>Email</h2>
<p>Email Notifications: Customize preferences for receiving marketing updates, newsletters, and alerts.</p>
<mat-slide-toggle formControlName="EmailNotifications">Receive Email notifications</mat-slide-toggle>

<h2 class="mt-4">SMS</h2>
<p>SMS Notifications: Opt-in or opt-out of text message alerts.</p>
<mat-slide-toggle disabled formControlName="SMSNotifications">Receive SMS notifications</mat-slide-toggle>
<mat-toolbar class="mt-5 align-items-center">
<button type="submit" mat-raised-button color="primary" class="text-white">Save</button>
</mat-toolbar>
</form>