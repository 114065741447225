export const environment = {
  production: false,
  PARSE_APP_ID: 'EQQ6FQG4u6iPbvh0G2ITG6cqUDuIbf1Mkl64sqt4',
  PARSE_JS_KEY: 'LNO2IMsRFjycE6O9Cdr582e1RrOe1T7AGdgV8pPN',
  serverURL: 'https://parseapi.back4app.com',
  // serverURL: 'http://localhost:1337/parse',
  gmap: 'AIzaSyBvXRT1UnZ4x0tPusv91I0LXFZq9iY1-1A',
  liveQueryServerURL: 'wss://advibe.b4a.io',
  placeholderImage: 'assets/letterhead.png'
};
