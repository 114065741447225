import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as Parse from "parse";
import { ParentService } from 'src/app/admin/services/parent.service';
import { AuthService } from 'src/app/auth/auth.service';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  notificationsForm: FormGroup;
  currentUser: Parse.User;
  constructor(private fb: FormBuilder, private dataService: DataService, private authService: AuthService, private router: Router, private parentService: ParentService) { }

  ngOnInit(): void {

    if(this.router.url.includes('admin')){
      this.parentService.activatedParent$.subscribe(res => {
        this.currentUser = res;
        this.notificationsForm = this.fb.group({
          EmailNotifications: [this.currentUser ? res.EmailNotifications : false],
          SMSNotifications: [this.currentUser ? res.SMSNotifications : false]
        })
      });
    }else{
      this.authService.$User.subscribe(res => {
        this.currentUser = res;
        this.notificationsForm = this.fb.group({
          EmailNotifications: [this.currentUser ? res.get('EmailNotifications') : false],
          SMSNotifications: [this.currentUser ? res.get('SMSNotifications') : false]
        })
      });
    }
  }

  savePreferences(){
    const userID = this.router.url.includes('admin') ? this.currentUser.ID : this.currentUser.get('ID');
    this.dataService.updateUserNotificationSettings(userID, this.notificationsForm.value).then(res => console.log(res)).catch(err =>console.log(err))
  }

}
