import { Injectable } from '@angular/core';
import { Practitioner } from 'backend/interfaces/practitioner.interface';
import { Location } from 'backend/interfaces/location.interface';
import { Service } from 'backend/interfaces/service.interface';
import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';
import { Group } from 'backend/interfaces/group.interface';
import { Parent } from 'backend/interfaces/parent.interface';
import { Ad } from 'backend/interfaces/ad.interface';
import { ParseClasses } from 'backend/enum/ParseClasses.enum';
import { Business } from 'backend/interfaces/business.interface';
import { NavigationEnd, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  services = new BehaviorSubject<Service[] | null>(null);
  services$ = this.services.asObservable();
  locations = new BehaviorSubject<Location[] | null>(null);
  locations$ = this.locations.asObservable();
  albums = new BehaviorSubject<any[] | null>(null);
  albums$ = this.albums.asObservable();
  practitioners = new BehaviorSubject<Practitioner[] | null>(null);
  practitioners$ = this.practitioners.asObservable();
  groups = new BehaviorSubject<Group[] | null>(null);
  groups$ = this.groups.asObservable();
  parent = new BehaviorSubject<Parent | null>(null);
  parent$ = this.parent.asObservable();
  business = new BehaviorSubject<Business | null>(null);
  business$ = this.business.asObservable();
  groupCount = new BehaviorSubject<number | null>(null);
  usercount = new BehaviorSubject<number | null>(493);
  usercount$ = this.usercount.asObservable();
  groupCount$ = this.groupCount.asObservable();
  currentUrl;
  previousUrl;
  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        console.log(event)
      };
    });
  }

  getServices(displayLimit?: number, page?: number): Promise<void> {
    displayLimit = 50;
    page = 0;
    return Parse.Cloud.run('getServices', { displayLimit, page }).then((result) => {
      this.services.next(result);
    });
  }

  getGroups(displayLimit?: number, page?: number): Promise<Group[]> {
    return Parse.Cloud.run('getGroups', { displayLimit, page });
  }

  getLocations(): Promise<Location[]> {
    return Parse.Cloud.run('getLocations').then((result) => {
      this.locations.next(result);
    });
  }

  getPractitioners(): Promise<Practitioner[]> {
    return Parse.Cloud.run('getPractitioners').then((result) => {
      this.practitioners.next(result);
    });
  }

  getCalendarBusy(from: string, to: string): Promise<any>{
    return Parse.Cloud.run('getCalendarBusy', { from, to });
  }

  getAvailableTimeSlots(day: number, month: number, year: number, duration: number): Promise<any>{
    return Parse.Cloud.run('getAvailableTimeSlots', {day, month, year, duration})
  }

  getAvailableTimes(duration: number, day: number, month: number, year: number, location: string, preferredPractitioner: string): Promise<any>{
    return Parse.Cloud.run('getAvailableTimes', {duration, day, month, year, location, preferredPractitioner})
  }

  getAlbums(id: string): Promise<any[]> {
    return Parse.Cloud.run('getAlbumList', { id }).then((result) => {
      this.albums.next(result);
    });
  }


  getGroupById(id: string): Promise<Group> {
    return Parse.Cloud.run('getGroupById', { id });
  }

  saveUser(user: Parent):Promise<any>{
    return Parse.Cloud.run('updateUserProfile', { user })
  }

  followGroup(group: Group): Promise<Group> {
    return Parse.Cloud.run('followGroup', { group }).then((result) => {
      return result;
    });
  }

  favoriteBusiness(businessId: string): Promise<Business[]> {
    return Parse.Cloud.run('favoriteBusiness', { businessId }).then((result) => {
      return result;
    });
  }

  unFavoriteBusiness(businessId: string): Promise<Business[]> {
    return Parse.Cloud.run('unFavoriteBusiness', { businessId }).then((result) => {
      return result;
    });
  }

  unfollowGroup(group: Group): Promise<Group> {
    return Parse.Cloud.run('unfollowGroup', { group }).then((result) => {
      return result;
    });
  }

  getGroupCount(): Promise<number>{
    return Parse.Cloud.run('getGroupCount').then((result) => {
      this.groupCount.next(result);
    });
  }

  async getUserCount(): Promise<number>{
    let query = new Parse.Query(ParseClasses.USER);
    return await query.count().then(res => {
      this.usercount.next(493+res)
    }).catch(error => {
      console.log(`Failed to retrieving objects, with error code: ${error.message}`);
      this.usercount.next(493)
    });
  }

  getAdsByCategoryId(id: string): Promise<any>{
    return Parse.Cloud.run('getAdsByCategoryId', { id })
  }

  getFeaturedAds(): Promise<any>{
    return Parse.Cloud.run('getFeaturedAds', { })
  }

  contactUsEmail(form: any): Promise<any>{
    return Parse.Cloud.run('contactUsEmail', { form });
  }

  updateUserNotificationSettings(userID: string, form: any): Promise<any>{
    return Parse.Cloud.run('updateUserNotificationSettings', { userID, form });
  }

  updateUserPassword(userID: string, form: any): Promise<any>{
    return Parse.Cloud.run('updateUserPassword', { userID, form });
  }

  getBusinessByName(name: string): Promise<any> {    
    return Parse.Cloud.run('getBusinessByName', { name }).then(res => {
      this.business.next(res)
      return 'complete'
    }).catch(err => {
      return err.toString()
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }  
}
