import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PublicComponent } from './public.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { CategoriesComponent } from './categories/categories.component';
import { ViewCategoryComponent } from './view-category/view-category.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ViewBusinessDialogComponent } from '../shared/view-business-dialog/view-business-dialog.component';
import { ViewBusinessComponent } from '../shared/view-business/view-business.component';


const routes: Routes = [
    { redirectTo: 'home', pathMatch: 'full', path: '' },
    {
        path: '',
        component: PublicComponent,
        children: [
            { path: 'home', component: HomeComponent },
            { path: 'about', component: AboutComponent },
            { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
            { path: 'privacy-policy', component: PrivacyPolicyComponent },
            { path: 'contact', component: ContactComponent },
            { path: 'categories', component: CategoriesComponent },
            { path: 'category/:id', component: ViewCategoryComponent },
            { path: 'business/:name', component: ViewBusinessComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes), ],
    exports: [RouterModule]
})
export class AdminRoutingModule {}
