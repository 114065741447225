import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Parent } from 'backend/interfaces/parent.interface';
import { Student } from 'backend/interfaces/student.interface';
import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    $User = new BehaviorSubject<Parse.Object>(null);
    checkingAuth: boolean = false;
    constructor(private router: Router) {
        if (Parse.User.current()) {
            this.checkingAuth = true;
            Parse.User.current()
                .fetch()
                .then((fetchedUser) => {
                    return this.setUser(fetchedUser);
                })
                .then(() => {
                    this.checkingAuth = false;
                })
                .catch(() => {
                    this.checkingAuth = false;
                });
        }
    }

    getCheckoutId(amount: number) {
        return Parse.Cloud.run('tokenizeInitialPayment', { amount });
    }

    getPaymentStatus(checkoutId: string, userId: string) {
        return Parse.Cloud.run('getPaymentStatus', { checkoutId, userId });
    }

    async login(email: string, password: string) {
        sessionStorage.removeItem('clientID');
        return Parse.User.logIn(email, password)
            .then((user) => {
                return user.fetch();
            })
            .then((fetchedUser) => {
                this.setUser(fetchedUser);
                return fetchedUser;
            })
            .catch((err) => {
                this.setUser(null);
                throw err.message;
            });
    }
    getUser() {
        return this.$User.getValue();
    }

    setUser(user: Parse.User) {
        this.$User.next(user);
    }

    register(user: any) {
        return Parse.Cloud.run('register', user);
    }

    signup(parent: Parent, password: string): Promise<any> {
        return Parse.Cloud.run('signup', { parent, password });
    }

    forgotPassword(email: string) {
        return Parse.User.requestPasswordReset(email);
    }

    signOut() {
        this.$User.next(null);
        Parse.User.logOut();
        sessionStorage.clear();
        this.router.navigateByUrl('login');
    }
    isAuthenticated() {
        return Parse.User.current();
    }
    userFetched() {
        if (this.$User && this.$User.getValue()) {
            return Promise.resolve(this.$User.getValue());
        }
        return Parse.User.current().fetch();
    }
    getUserRole() {
        return Parse.User.current().get('role');
    }

    getUserDashboard(){
        const id = this.getUser().id;
        return Parse.Cloud.run('getUserDashboard', { id }).then(res => {
            this.$User.next(res);
        });
    }
}
