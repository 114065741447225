<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
    <div>
        <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>

<div mat-dialog-title class="d-flex relative">
    <h1>{{title}}</h1>
    <div class="spacer"></div>
        <div class="initials">
            <fa-icon (click)="toggleCamera()" [icon]="faCamera" class="link"></fa-icon> 
        </div>
        <div class="initials ml-2">
            <fa-icon (click)="uploadFile()" [icon]="faUpload" class="link"></fa-icon> 
        </div>
    </div>
    
    <div mat-dialog-content class="text-center">
        <input
        type="file"
        class="file-input"
        (change)="onFileSelected($event)"
        #fileInput
        style="display: none"
      />
      <app-camera style="margin: auto;" *ngIf="showCamera" (capturedImage)="showThumb($event)"></app-camera>
      <br/>
      <div class="d-flex align-items-center justify-content-center">
            <div class="profile-pic-initials lg">
                <img class="text-center margin-auto img-fluid" (click)="toggleCamera()" src="./../../../assets/user.png" #webcamImageDisplay alt="webcam image">
            </div>
            <h1>{{recognisedFace}}</h1>
            <button mat-stroked-button (click)="search()">Get Profile</button>
        </div>
    </div>
    <div mat-dialog-actions class="d-flex">
      <input
      type="file"
      class="file-input"
      (change)="onFileSelected($event)"
      #recognitionFileInput
      style="display: none"
    />
        <button mat-button (click)="onNoClick()">Close</button>
        <div class="spacer"></div>
        <button mat-raised-button [disabled]="!webcamImage && !uploadedImage" color="secondary" (click)="recognise()">Identify Face</button>
    </div>