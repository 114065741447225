<div id="carousel" class="h-100 bg-50perc-black carousel slide border-primary d-flex align-items-center flex-column" data-ride="carousel">
    <div class="h-100 carousel-inner">
        <div class="h-100 carousel-item active text-center no-border" *ngIf="data.ads.length < 1">
              
            <div class="h-100 advertensie d-flex flex-column" [style.backgroundImage]="'../assets/imgUpload.png'">
              <div class="bg-50perc-black p-3 m-3">
                <h1 class="text-white">Easily get your business offering infront of more people</h1>
                <p class="text-white">{{body}}</p>
              </div>
            </div>
          <button class="m-3 mb-5" mat-raised-button color="primary">Read more</button>
        </div>
        <ng-container *ngIf="data.ads.length > 0">
          <div class="h-100 carousel-item no-border relative" *ngFor="let ad of data.ads; let i = index" [ngClass]="{'active': i === 0}" [ngStyle]="{'background-color':'#'+ad.adColor?.hex}">
            <h3 class="m-0 text-center p-2"><a>{{ad.business.get('Name')}}</a></h3>
              <div class="h-100 advertensie d-flex flex-column" [style]="" [ngStyle]="{'background-image':'url'+'('+ad.image1.source +')'}">
                <div class="bg-50perc-black p-3 m-3">
                  <h1 class="text-white">{{ad.title}}</h1>
                  <p class="text-white">{{ad.body}}</p>
                </div>
              </div>

          </div>
        </ng-container>
      </div>
    <a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
      <fa-icon class="text-primary" [icon]="faPrev" size="2x"></fa-icon>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carousel" role="button" data-slide="next">
      <fa-icon class="text-primary" [icon]="faNext" size="2x"></fa-icon>
      <span class="sr-only">Next</span>
    </a>

  </div>