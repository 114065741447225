import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  isAdmin = false;
  currentUser = '';
  constructor(private authService: AuthService, private router: Router) { 
    authService.$User.subscribe((res)=>{
      if(res) {
        this.currentUser = res.get('Name');
        this.isAdmin = res.get('role') === 'admin';
      }
    })
  }
  ngOnInit(): void {
    if(this.currentUser !== '') {
      if(this.isAdmin){
        this.router.navigate(['admin', 'appointments']);
      } else {
        this.router.navigate(['parent']);
      }
    }
  }

  newAppointment(): void{
    this.router.navigateByUrl('appointment');
  }

  docuMerge(): void{
    this.router.navigateByUrl('pdf-worker');
  }

  explore(): void{
    this.router.navigateByUrl('explore');
  }
  goTo(): void {
    this.router.navigate(['','home'])
  }
}
