<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<div class="p-3" *ngIf="!loading">
  <p>Student Profile</p>
  <form class="user" [formGroup]="profileForm" (ngSubmit)="saveProfile()">
    <div class="d-flex flex-column">
      <div class="row" *ngIf="isAdmin">
        <div class="col-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Parent 1 ID</mat-label>
            <input
              matInput
              placeholder="Parent 1 ID Number"
              name="ParentID1"
              formControlName="ParentID1"
            />
            <mat-error *ngIf="profileForm.controls.ParentID1.invalid"
              >Parent 1 ID is Required</mat-error
            >
            <button mat-icon-button matSuffix (click)="viewParent(profileForm.controls.ParentID1.value)">
                <fa-icon [icon]="faUser"></fa-icon>
              </button>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Parent 2 ID</mat-label>
            <input
              matInput
              placeholder="Parent 2 ID Number"
              name="ParentID2"
              formControlName="ParentID2"
            />
            <mat-error *ngIf="profileForm.controls.ParentID2.invalid"
              >Parent 2 ID is Required</mat-error
            >
            <button mat-icon-button matSuffix (click)="viewParent(profileForm.controls.ParentID2.value)">
                <fa-icon [icon]="faUser"></fa-icon>
              </button>
          </mat-form-field>
        </div>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" name="name" formControlName="Name" />
        <mat-error *ngIf="profileForm.controls.Name.invalid"
          >Name is Required</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Surname</mat-label>
        <input matInput placeholder="Surname" formControlName="Surname" />
        <mat-error *ngIf="profileForm.controls.Surname.invalid"
          >Surname is Required</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>ID</mat-label>
        <input matInput placeholder="Id" formControlName="ID" />
        <mat-error *ngIf="profileForm.controls.ID.invalid"
          >ID is Required</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Date of birth</mat-label>
        <input
          readonly
          matInput
          [matDatepicker]="picker1"
          formControlName="DOB"
        />
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Title</mat-label>
        <input matInput placeholder="Title" formControlName="Title" />
        <mat-error *ngIf="profileForm.controls.Title.invalid"
          >Title is Required</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Gender</mat-label>
        <input matInput placeholder="Gender" formControlName="Gender" />
        <mat-error *ngIf="profileForm.controls.Gender.invalid"
          >Gender is Required</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Problems</mat-label>
        <input
          matInput
          placeholder="HealthProblems"
          formControlName="HealthProblems"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Notes</mat-label>
        <input matInput placeholder="Notes" formControlName="Notes" />
      </mat-form-field>
      <mat-form-field *ngIf="isAdmin" appearance="fill">
        <mat-label>{{ studentClass ? studentClass.name : "" }} Class</mat-label>
        <mat-select formControlName="Group" name="Group">
          <mat-option *ngFor="let group of groups" [value]="group">{{
            group.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-toolbar class="d-flex justify-content-end">
      <button
        type="submit"
        [disabled]="!profileForm.valid || saving"
        mat-raised-button
        color="primary"
      >
        Save
      </button>
    </mat-toolbar>
  </form>
</div>
