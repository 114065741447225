import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { faTimes, faMapMarker, faPen, faPhone, faComment, faInfo, faStar, faChevronRight, faChevronLeft, faChevronDown, faPhotoVideo, faHandshake, faEnvelope, faCalendar, faBuilding, faQuoteLeft, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { Business } from 'backend/interfaces/business.interface';
import { AuthService } from 'src/app/auth/auth.service';
import { BusinessDataService } from 'src/app/business/services/data.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-view-business',
  templateUrl: './view-business.component.html',
  styleUrls: ['./view-business.component.scss']
})
export class ViewBusinessComponent implements OnInit {

  constructor(private dataService: DataService, private router: Router, private ar: ActivatedRoute, private businessDataService: BusinessDataService, private authService: AuthService) { }
  faTimes = faTimes;
  faPin = faMapMarker;
  faPen = faPen;
  faPhone = faPhone;
  faComment = faComment;
  faInfo = faInfo;
  faStar = faStar;
  faNext = faChevronRight;
  faPrev = faChevronLeft;
  faDown = faChevronDown;
  faPhoto = faPhotoVideo;
  faShake = faHandshake;
  faEnvelope = faEnvelope;
  faCalendar = faCalendar;
  faBuilding = faBuilding;
  faQuoteLeft = faQuoteLeft;
  faCheckList = faCheckDouble;
  ads;
  loading = false;
  isFavorite = false;
  business: Business;
  businessName;
  loggedIn = false;

  ngOnInit(): void {
    this.loggedIn = this.authService.isAuthenticated() !== null;
    this.loading = true;
    this.businessName = this.ar.snapshot.params['name'];
    this.dataService.business$.subscribe(res => {
      if(res){
      this.business = res;
      this.loading = false;
      }else{
        this.dataService.getBusinessByName(this.businessName).then(res => {
          this.loading = false;
        });
      }
    })
  }

  favouriteBusiness(business: any){
    this.loading = true;
    this.dataService.favoriteBusiness(business.id).then(async res => {
      this.isFavorite = true;
        await this.businessDataService.getUserFavorites(res).then(res => {
          this.businessDataService.favorites.next(res);
          this.loading = false
        })
    }).catch(err => console.log(err));
  }

  unFavouriteBusiness(id: string){
    this.loading = true;
    this.dataService.unFavoriteBusiness(id).then(async (res) => {
      this.isFavorite = false;
        await this.businessDataService.getUserFavorites(res).then(res => {
          this.businessDataService.favorites.next(res);
          this.loading = false
        })
    });
  }

  back(): void{
    const prevUrl = this.dataService.getPreviousUrl();
    if(prevUrl === this.router.url){
      this.router.navigateByUrl('category/'+ this.business.Category.id);
    }else{
      this.router.navigateByUrl(prevUrl);
    }
  }

}
