import { Color } from '@angular-material-components/color-picker';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { Ad } from 'backend/interfaces/ad.interface';
import { Business } from 'backend/interfaces/business.interface';
import { FileUpload } from 'backend/interfaces/file-upload';
import { SideNavService } from 'src/app/admin/services/side-nav.service';
import { BusinessDataService } from 'src/app/business/services/data.service';

@Component({
  selector: 'app-ad-form',
  templateUrl: './ad-form.component.html',
  styleUrls: ['./ad-form.component.scss']
})
export class AdFormComponent implements OnInit {
    loading = false;
    uploadedImage1: FileUpload;
    uploadedImage2: FileUpload;
    uploadedImage3: FileUpload;
    faImage = faImage;
    businessess = [];
    adCategory = '';
    public color: ThemePalette = 'primary';
    public listColors = ['primary', 'accent', 'warn'];
    @ViewChild('fileInput1') fileInput1: ElementRef;
    @ViewChild('fileInput2') fileInput2: ElementRef;
    @ViewChild('fileInput3') fileInput3: ElementRef;
    @ViewChild('Image1Display') Image1Display: ElementRef;
    @ViewChild('Image2Display') Image2Display: ElementRef;
    @ViewChild('Image3Display') Image3Display: ElementRef;
    business: Business;
    ad: Ad;
    ads: any[];
    adForm: FormGroup;
    constructor(private fb: FormBuilder, private dataService: BusinessDataService, private sideNavService: SideNavService, private snackbar:MatSnackBar) { }
  
    async ngOnInit() {
      this.loading = true;
      this.adForm = this.fb.group({
        title: ['', Validators.required],
        body: ['', Validators.required],
        business: ['', Validators.required],
        adColor: ['']
      })

      await this.dataService.parent$.subscribe(res => {
        if(res){
          this.business = res;
        }
      });
        await this.dataService.businesses$.subscribe(res => {
          if(res){
          this.businessess = res;
          }
        });
        await this.dataService.activeAd$.subscribe(res => {
          console.log(res)
          if(res){

            this.ad = res;
            this.adCategory = res.category;
            this.adForm.patchValue({
              adColor: res.adColor ? new Color(res.adColor.r, res.adColor.g, res.adColor.b) : '',
              title: res.title,
              body: res.body,
              business: res.business

            });
            if(res.image1.source){
              this.uploadedImage1 = {
                source: res.image1.source,
                name: res.image1.name
              }
              this.Image1Display.nativeElement.src = res.image1.source;
            }
            if(res.image2.source){
              this.uploadedImage2 = {
                source: res.image2.source,
                name: res.image2.name
              }
              this.Image2Display.nativeElement.src = res.image2.source;
            }
            if(res.image3.source){
              this.uploadedImage3 = {
                source: res.image3.source,
                name: res.image3.name
              }
              this.Image3Display.nativeElement.src = res.image3.source;
            }
          }
        });
        this.loading = false;
  
    }
  
  
    async onFile1Selected(event): Promise<void>{
      let reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const file: File = event.target.files[0];
        reader.readAsDataURL(file);
  
        reader.onload = () => {
          if (file.type.includes('image')) {
            this.uploadedImage1 = {
              source: reader.result as any,
              name: file.name
            }
            this.Image1Display.nativeElement.src = reader.result;
          }
        };
  
      }
    }
    async onFile2Selected(event): Promise<void>{
      let reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const file: File = event.target.files[0];
        reader.readAsDataURL(file);
  
        reader.onload = () => {
          if (file.type.includes('image')) {
            this.uploadedImage2 = {
              source: reader.result as any,
              name: file.name
            }
            this.Image2Display.nativeElement.src = reader.result;
          }
        };
  
      }
    }
    async onFile3Selected(event): Promise<void>{
      let reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const file: File = event.target.files[0];
        reader.readAsDataURL(file);
  
        reader.onload = () => {
          if (file.type.includes('image')) {
            this.uploadedImage3 = {
              source: reader.result as any,
              name: file.name
            }
            this.Image3Display.nativeElement.src = reader.result;
          }
        };
  
      }
    }
    uploadFile1(){
      this.fileInput1.nativeElement.value = null;
      this.fileInput1.nativeElement.click();
    }
    uploadFile2(){
      this.fileInput2.nativeElement.value = null;
      this.fileInput2.nativeElement.click();
    }
    uploadFile3(){
      this.fileInput3.nativeElement.value = null;
      this.fileInput3.nativeElement.click();
    }
    setAdCategory(b: MatSelectChange){
      console.log(b)
      this.adCategory = b.value.Category.id
      // this.adCategory = b.Category.id;
    }
    async submit(): Promise<void>{
      this.loading = true;
      const ad: Ad = {
        id: this.ad?.id ? this.ad.id : '',
        title: this.adForm.controls['title'].value,
        body: this.adForm.controls['body'].value,
        image1: this.uploadedImage1,
        image2: this.uploadedImage2,
        image3: this.uploadedImage3,
        category: this.adCategory,
        business: this.adForm.controls['business'].value.id,
        adColor: this.adForm.controls['adColor'].value
      }
      this.dataService.saveAd(ad).then(async () => {
        this.sideNavService.close()
          this.dataService.getAdsByBusinessId(ad.business);
        this.loading = false;
      }).catch(err => {
        this.snackbar.open(err, 'OK', {
          duration: 3000
        });
        this.loading = false;
      });
    }
  
  }