import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faBuilding, faCalendar, faCheckDouble, faChevronDown, faChevronLeft, faChevronRight, faComment, faEnvelope, faHandshake, faInfo, faInfoCircle, faMapMarker, faPen, faPhone, faPhoneSlash, faPhotoVideo, faQuoteLeft, faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Business } from 'backend/interfaces/business.interface';
import { FileUpload } from 'backend/interfaces/file-upload';
import { Group } from 'backend/interfaces/group.interface';
import { BusinessDataService } from 'src/app/business/services/data.service';
import { DataService } from 'src/app/services/data.service';
interface DialogData {
  business: {
  id: string;
  Name: string;
  AccountID?: string;
  RegistrationNumber?: string;
  Category: Group;
  OwnershipInfo?: string;
  Address?: string;
  Website?: string;
  Mobile: string;
  Email: string;
  Notes?: string;
  About?: string;
  InformedDataConsent?: boolean;
  Document?: FileUpload;
  DriveId?: string;
  OwnerID?: string;
  RegistrationDate: string;
  Status: string;
  Logo: any;
  ProfilePicture?: any;
  },
  isFavorite?: boolean
}
@Component({
  selector: 'app-view-business-dialog',
  templateUrl: './view-business-dialog.component.html',
  styleUrls: ['./view-business-dialog.component.scss']
})

export class ViewBusinessDialogComponent implements OnInit {
  faTimes = faTimes;
  faPin = faMapMarker;
  faPen = faPen;
  faPhone = faPhone;
  faComment = faComment;
  faInfo = faInfo;
  faStar = faStar;
  faNext = faChevronRight;
  faPrev = faChevronLeft;
  faDown = faChevronDown;
  faPhoto = faPhotoVideo;
  faShake = faHandshake;
  faEnvelope = faEnvelope;
  faCalendar = faCalendar;
  faBuilding = faBuilding;
  faQuoteLeft = faQuoteLeft;
  faCheckList = faCheckDouble;
  ads;
  loading = false;
  constructor(private dataService: DataService, private businessDataService: BusinessDataService, public dialogRef: MatDialogRef<ViewBusinessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    await this.businessDataService.getAdsByBusinessId(this.data.business.id);
    await this.businessDataService.ads$.subscribe(async res => this.ads = await res);
    this.loading = false
  }

  favouriteBusiness(business: Business){
    this.loading = true;
    this.dataService.favoriteBusiness(business.id).then(async res => {
      this.data.isFavorite = true;
        await this.businessDataService.getUserFavorites(res).then(res => {
          this.businessDataService.favorites.next(res);
          this.loading = false
        })
    });
  }

  unFavouriteBusiness(id: string){
    this.loading = true;
    this.dataService.unFavoriteBusiness(id).then(async (res) => {
      this.data.isFavorite = false;
        await this.businessDataService.getUserFavorites(res).then(res => {
          this.businessDataService.favorites.next(res);
          this.loading = false
        })
    });
  }

}
