import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Parent, ParentConsent } from 'backend/interfaces/parent.interface';
import { Observable } from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatStepper, StepperOrientation} from '@angular/material/stepper';
import {map} from 'rxjs/operators';
import { ParentService } from 'src/app/admin/services/parent.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from "moment";
import { DataService } from 'src/app/services/data.service';
import { AuthService } from 'src/app/auth/auth.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input() profile: Parent = {
    Name: '',
    Surname: '',
    ID: '',
    Occupation: '',
    Title: '',
    Gender: '',
    Address: '',
    Mobile: '',
    Email: this.parentService.newParentEmail ? this.parentService.newParentEmail : '',
    HealthProblems: '',
    Notes: '',
    MedicalAidName: '',
    MedicalAidNumber: '',
    MedicalAidMainMember: '',
    isMainContact: false,
    PartnerID: '',
  };
  saving = false;
  @Output() emitProfile: EventEmitter<any> = new EventEmitter();
  @Output() emitMedical = new EventEmitter<Object>();
  @ViewChild('stepper') private stepper: MatStepper;
  constructor(private dataService: DataService, private fb: UntypedFormBuilder, private breakpointObserver: BreakpointObserver, private parentService: ParentService, private router: Router, private _snackBar: MatSnackBar, private authService: AuthService) {
    this.stepperOrientation = breakpointObserver.observe('(min-width: 800px)')
      .pipe(map(({matches}) => matches ? 'horizontal' : 'vertical'));
  }
  profileForm: UntypedFormGroup;
  // medicalForm: UntypedFormGroup;
  thirdFormGroup = this.fb.group({
    thirdCtrl: ['', Validators.required]
  });
  stepperOrientation: Observable<StepperOrientation>;
  async ngOnInit(): Promise<void> {
    this.authService.$User.subscribe(async (res)=>{
      if(res){
        this.profile = {
          Name: res.get('Name'),
          Surname: res.get('Surname'),
          ID: res.get('ID'),
          Address: res.get('Address'),
          Mobile: res.get('Mobile'),
          Email: res.get('email')
        }
      }
    });
    console.log(this.profile)
    // this.parentService.activatedParent$.subscribe(res => {
    //   if(res){
    //   this.profile = res;
    //   }else{
    //     this.profile = {
    //       Name: '',
    //       Surname: '',
    //       ID: '',
    //       Occupation: '',
    //       Title: '',
    //       Gender: '',
    //       Address: '',
    //       Mobile: '',
    //       Email: '',
    //       HealthProblems: '',
    //       Notes: '',
    //       MedicalAidName: '',
    //       MedicalAidNumber: '',
    //       MedicalAidMainMember: '',
    //       isMainContact: false,
    //       PartnerID: '',
    //     };
    //   }
    // });
    this.profileForm = this.fb.group({
      Name: [this.profile.Name, Validators.required],
      Surname: [this.profile.Surname, Validators.required],
      ID: [this.profile.ID, Validators.required, Validators.length > 6],
      Address: [this.profile.Address, Validators.required],
      Mobile: [this.profile.Mobile, Validators.required],
      Email: [this.profile.Email, Validators.required]})
    //   this.medicalForm = this.fb.group({
    //   HealthProblems: [this.profile.HealthProblems, Validators.required],
    //   Notes: [this.profile.Notes],
    //   MedicalAidName: [this.profile.MedicalAidName, Validators.required],
    //   MedicalAidNumber: [this.profile.MedicalAidNumber, Validators.required],
    //   MedicalAidMainMember: [this.profile.MedicalAidMainMember, Validators.required]
    // });
  }
  async saveProfile(){
    if(this.profileForm.dirty){
      this.saving = true;
      // const parentID = this.profileForm.controls['ID'].value;
      // let DOB = '';
      // const mm = parentID.substring(0,2)
      // const dd = parentID.substring(2,4)
      // const yy = parentID.substring(4,6)
      // if(parentID.length > 10){
      //   DOB = moment(new Date(mm+'/'+dd+'/'+yy)).format('MMM DD yyyy')
      //   console.log(DOB)
      // }
      // this.profileForm.controls['PartnerID'].patchValue(!this.profileForm.controls['hasPartner'].value ? '' : this.profileForm.controls['PartnerID'].value)
      this.dataService.saveUser({...this.profileForm.value})
      .then((res) => {
        this.parentService.newParentEmail = undefined;
        this.openSnackBar('Profile saved');
        // this.stepper.next();
        this.parentService.getParentCount();
        this.saving = false
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.saving = false;
      });
      this.profileForm.markAsPristine();
      this.profile.ID = this.profileForm.controls['ID'].value;
    } else {
      // this.stepper.next();
    }
  }
  // async saveMedicalDetails(){
  //   if(this.medicalForm.dirty){
  //     this.saving = true;
  //     const medicalForm = {...this.medicalForm.value, 
  //       ID: this.profile.ID};

  //       this.parentService
  //       .saveParentMedicalDetails(medicalForm)
  //       .then(() => {
  //         this.openSnackBar('Medical details saved');
  //         this.saving = false;
  //         this.stepper.next();
  //       })
  //       .catch((err) => {
  //         this.openSnackBar(err);
  //         this.saving = false;
  //       });
  //     this.medicalForm.markAsPristine();
  //   } else {
  //     this.stepper.next();
  //   }
  // }
  getErrorMessage() {
    if (this.profileForm.controls['Name'].hasError('required')) {
      return 'You must enter a value';
    }
  
    return this.profileForm.controls['Name'].hasError('required') ? 'Not a valid name' : '';
  }

  async saveParentConsent(event: {student: ParentConsent, signature: string}){
    this.saving = true;
    this.parentService
      .saveParentConsent(event.student)
      .then(() => {
        this.openSnackBar('Consent saved');
        this.emitProfile.emit(event.student);
        this.saving = false;
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.saving = false;
      });
  }


  back(){
    this.stepper.previous();
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }
}



