<div class="m-0 row justify-content-center bg-black h-100">
    <div class="desktop col-md-5 p-3 text-center flex-column align-items-center justify-content-center ">
      <img class="link img-fluid relative" src="assets/icon.png" />
      <a href="mailto: info@advibe.co.za" class="text-white">info&#64;advibe.co.za</a>
      </div>
    <div class="col-md-7 bg-image-1 curve">
        <div class="p-2 d-flex flex-column justify-content-between h-100">
            <div class="spacer"></div>
            <div class="flex-1">
            <div>
                <h1 class="mat-display-2 mb-3 mt-5">Contact us</h1>
                <p class="mat-subheading-2 mb-5">
                    We are standing by to assist
                </p>
            </div>
    <mat-progress-bar
        [mode]="loading ? 'indeterminate' : 'determinate'"
        [value]="0">
    </mat-progress-bar>
    
    
      <form *ngIf="FormData" [formGroup]="FormData" (submit)="onSubmit()">
          <div class="d-flex row p-3">
          <div class="col-md-12 d-flex flex-column">
              <mat-form-field appearance="outline">
                  <mat-label>Name</mat-label>
                  <input matInput placeholder="name" name="name" formControlName="name">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input type="email" matInput placeholder="email" name="email" formControlName="email">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Number</mat-label>
                <input matInput placeholder="number" name="number" formControlName="number">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Message</mat-label>
                <textarea matInput placeholder="message" message="message" formControlName="message"></textarea>
            </mat-form-field>
              </div>
              </div>
                  <div mat-toolbar class="d-flex justify-content-between p-3">
            {{noResults ? 'No Results Found' : ''}}
            <div class="spacer"></div>
            <button mat-raised-button color="accent" [disabled]="loading" type="submit" class="btn btn-outline-secondary mb-5">
                {{ loading ? 'Sending' : 'Send' }}
            </button>
        </div>
              </form>
    </div>
    </div>
  </div>
  </div>
