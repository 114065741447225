<h1>Password Management</h1>

<p class="mat-subheading-2 mb-5">
    We'll send you a link to reset your password!
</p>
<div class="form-group">
    <label>Username</label>
    <input name="email" type="email" class="form-control form-control-user" aria-describedby="emailHelp"
        placeholder="Email" disabled [(ngModel)]="email">
</div>
<form [formGroup]="securityForm" (ngSubmit)="saveSecurityUpdates()" class="d-flex flex-column">
    <mat-form-field>
        <input matInput name="password" [type]="!passwordVisible ? 'password' : 'text'" aria-describedby="password"
        placeholder="Current password" formControlName="pw">
        <button type="button" mat-icon-button matSuffix (click)="passwordVisible = !passwordVisible" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordVisible">
            <mat-icon>{{passwordVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field>
        <input matInput name="npassword" [type]="!npasswordVisible ? 'password' : 'text'" aria-describedby="password"
        placeholder="New password" formControlName="npw">
        <button type="button" mat-icon-button matSuffix (click)="npasswordVisible = !npasswordVisible" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordVisible">
            <mat-icon>{{npasswordVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field>
        <input matInput name="cpassword" [type]="!cpasswordVisible ? 'password' : 'text'" aria-describedby="password"
        placeholder="Confirm new password" formControlName="cpw">
        <button type="button" mat-icon-button matSuffix (click)="cpasswordVisible = !cpasswordVisible" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordVisible">
            <mat-icon>{{cpasswordVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
    </mat-form-field>
    <p class="text-danger" *ngIf="errorMessage !== ''">{{errorMessage}}</p>

    <mat-toolbar class="mt-5 align-items-center">
    <button type="submit" mat-raised-button color="primary" class="text-white">Save</button>
    </mat-toolbar>
    </form>


<!-- <h1>Account Security</h1>
<p>Manage security settings and view recent account activity.</p> -->
