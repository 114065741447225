
<div #cameraConatainer class="d-flex flex-column align-items-center justify-content-center">

<webcam (click)="triggerSnapshot();" *ngIf="showWebcam" #cam [height]="300" [width]="400" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
[allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
[videoOptions]="videoOptions"
[imageQuality]="1"
(cameraSwitched)="cameraWasSwitched($event)"
(initError)="handleInitError($event)"
></webcam>
<br>
<!-- <canvas #cancancanvas></canvas> -->

<!-- <canvas #canvasRef></canvas> -->
<div class="w-100 d-flex justify-content-between">
<button mat-stroked-button (click)="toggleWebcam();">Toggle Webcam</button>
<button mat-raised-button color="accent" (click)="triggerSnapshot();">Take A Snapshot</button>
</div>
<br>
<!-- <button class="actionBtn" (click)="showNextWebcam(true);" [disabled]="!multipleWebcamsAvailable">Next Webcam</button>
<input id="cameraSwitchCheckbox" type="checkbox" [(ngModel)]="allowCameraSwitch"><label for="cameraSwitchCheckbox">Allow Camera Switch</label> -->
<!-- <br> -->
<!-- DeviceId: <input id="deviceId" type="text" [(ngModel)]="deviceId" style="width: 500px"> -->
<!-- <button (click)="showNextWebcam(deviceId);">Activate</button> -->
<!-- <br> -->
<!-- Preferred facing mode:
<input type="radio" value="user" name="facingMode" [(ngModel)]="facingMode"> User
<input type="radio" value="environment" name="facingMode" [(ngModel)]="facingMode"> Environment
<input type="radio" value="" name="facingMode" [(ngModel)]="facingMode"> No preference -->

<!-- <h4 *ngIf="messages.length > 0">Messages:</h4> -->
<!-- <ul *ngFor="let msg of messages">
<li>{{msg | json}}</li>
</ul> -->
</div>