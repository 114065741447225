
<mat-toolbar class="d-flex justify-content-between">
  <mat-card-title>Ads</mat-card-title>
  <div class="spacer"></div>

  <button class="mr-2" mat-mini-fab color="primary" aria-label="Reload" (click)="reloadAds()">
      <fa-icon class="text-white" [icon]="faUndo"></fa-icon> 
  </button>
  <button class="mr-2"  mat-mini-fab color="primary" aria-label="Create Add" (click)="createAd()">
    <fa-icon [icon]="faPlus" class="text-white"></fa-icon> 
  </button>
<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
    <div>
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>
</mat-toolbar>
  <div *ngIf="ads" class="row mb-4 mt-4 m-0">
    <div *ngFor="let ad of ads" class="col-md-12 d-flex mb-2 p-3" [ngStyle]="{'background-color':'#'+ad.adColor?.hex}">
      <mat-card class="w-100">
        <div class="row d-flex justify-content-center m-3">
          <div
            *ngIf="(ad && !ad?.image1) || ad?.image1?.source === ''"
            class="profile-pic-initials text-white"
          >
            <fa-icon size="2x" [icon]="faImage" class="text-white"></fa-icon>
          </div>
          <div
            *ngIf="ad && ad?.image1 && ad?.image1?.source !== ''"
            class="profile-pic-initials"
          >
            <img class="img-fluid" [src]="ad.image1.source" />
          </div>
        </div>
        <mat-card-header class="justify-content-center text-center">
          <mat-card-title>{{ ad.title }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <!-- <div class="row">
            <div class="col-6 p-2">
            <button type="button" mat-raised-button color="accent" class="btn-block" (click)="viewProfile(student.ID)">Open</button>
            </div>
          </div> -->
          <!-- <div class="m-3 d-flex justify-content-center flex-column" *ngIf="!student">
            <mat-card-subtitle>No student profile found for your email address.</mat-card-subtitle>
            <button mat-stroked-button color="secondary" (click)="createProfile(sideNavActions.NewStudent)">Create Profile</button>
          </div> -->
        </mat-card-content>
        <mat-card-footer class="p-3 d-flex justify-content-between">
          <button *ngIf="ad.id" type="button" (click)="openDeleteDialog(ad.id)" mat-stroked-button color="danger"><fa-icon [icon]="faBin"></fa-icon></button>
          <button *ngIf="ad.id" type="button" (click)="openAd(ad)" mat-raised-button color="accent">Open</button>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>

