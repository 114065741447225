import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { faBuilding, faCaretRight, faImage, faMapMarker, faPhone, faStar, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Business } from 'backend/interfaces/business.interface';
import { Group } from 'backend/interfaces/group.interface';
import { DataService } from 'src/app/services/data.service';
import { AdViewerDialogComponent } from 'src/app/shared/ad-viewer-dialog/ad-viewer-dialog.component';
import { ViewBusinessDialogComponent } from 'src/app/shared/view-business-dialog/view-business-dialog.component';

@Component({
  selector: 'app-view-category',
  templateUrl: './view-category.component.html',
  styleUrls: ['./view-category.component.scss']
})
export class ViewCategoryComponent implements OnInit {
  loading = false;
  groupId;
  category: Group;
  faAddressPin = faMapMarker;
  faPhone = faPhone;
  faUsers = faUsers;
  faImage = faImage;
  faBuilding = faBuilding;
  faStar = faStar;
  ads;
  faCaretRight = faCaretRight;
  constructor(private router: Router, private ar: ActivatedRoute, public dialog: MatDialog, private dataService: DataService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true
    this.groupId = this.ar.snapshot.params['id'];
    this.dataService.getGroupById(this.groupId).then(async res => {
      this.category = res;
      await this.dataService.getAdsByCategoryId(this.groupId).then(async res => {
        this.ads = await res
        console.log(res)
    });
      this.loading = false;
    });
  }

  favouriteBusiness(){
    this.router.navigate(['auth', 'register'])
  }

  viewBusiness(business: Business){
    this.dataService.business.next(business);
    this.router.navigate(['business/', business.Name])
    // const dialogRef = this.dialog.open(ViewBusinessDialogComponent, {
    //   width: '100%',
    //   height: '100%',
    //   data: {business},
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('Business was closed');
    // });
  }

  async viewBusinessFromAd(businessId: string): Promise<void>{
    this.loading = true;
    const business = await this.category.businessess.find(b => {
      return b.id === businessId
  });
  this.dataService.business.next(business);
  this.router.navigate(['business/', business.Name])
    // const dialogRef = this.dialog.open(ViewBusinessDialogComponent, {
    //   width: '100%',
    //   height: '100%',
    //   data: {business},
    // });
    // this.loading = false
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('Business was closed');
    // });
  }

  viewAds(ads: any){
    const dialogRef = this.dialog.open(AdViewerDialogComponent, {
      data: {ads},
      width: '90vw',
      height: '90vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Business was closed');
    });
  }

  goTo(route: string){
    this.router.navigate(['auth', 'register'])
  }
}
