import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatStepper, StepperOrientation} from '@angular/material/stepper';
import {map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from "moment";
import { Business, BusinessConsent } from 'backend/interfaces/business.interface';
import { BusinessService } from 'src/app/admin/services/business.service';
import { AuthService } from 'src/app/auth/auth.service';
import { GroupService } from 'src/app/admin/services/group.service';
import { BusinessStatus } from 'backend/enum/BusinessStatus.enum';
import { CategoriesComponent } from 'src/app/business/categories/categories.component';
import { Group } from 'backend/interfaces/group.interface';
import { BusinessDataService } from 'src/app/business/services/data.service';
import { SideNavService } from 'src/app/admin/services/side-nav.service';
@Component({
  selector: 'app-business-profile',
  templateUrl: './business-profile.component.html',
  styleUrls: ['./business-profile.component.scss']
})
export class BusinessProfileComponent implements OnInit {
  @Input() profile: Business;
  currentUser;
  categories: Group[] = []
  saving = false;
  @Output() emitProfile: EventEmitter<any> = new EventEmitter();
  @Output() emitMedical = new EventEmitter<Object>();
  @ViewChild('stepper') private stepper: MatStepper;
  statusArray = ['Created', 'Active', 'Inactive'];
  constructor(private fb: UntypedFormBuilder, private breakpointObserver: BreakpointObserver, private businessService: BusinessService, private router: Router, private _snackBar: MatSnackBar, private authService: AuthService, private groupService: GroupService, private businessDataService: BusinessDataService, private sideNavService: SideNavService) {
    this.stepperOrientation = breakpointObserver.observe('(min-width: 800px)')
      .pipe(map(({matches}) => matches ? 'horizontal' : 'vertical'));

      this.authService.$User.subscribe((res)=>{
        if(res){
          this.currentUser = res;
        }
      })
  }
  profileForm: UntypedFormGroup = this.fb.group({
    Name: ['', Validators.required],
    AccountID: [''],
    RegistrationNumber: [''],
    Category: ['', Validators.required],
    OwnershipInfo: [''],
    Address: ['', Validators.required],
    Website: [''],
    Mobile: [''],
    Email: [''],
    Notes: [''],
    InformedDataConsent: ['', Validators.required],
    OwnerID: ['', Validators.required],
    RegistrationDate: [''],
    Status: [''],
    About: ['', Validators.required],
  });
  medicalForm: UntypedFormGroup;
  thirdFormGroup = this.fb.group({
    thirdCtrl: ['', Validators.required]
  });
  stepperOrientation: Observable<StepperOrientation>;
  async ngOnInit(): Promise<void> {
    this.saving = true
    this.groupService.allGroups$.subscribe(async res => {
      if (res) {
        this.categories = await res;
      }
    });
    this.businessDataService.business$.subscribe(async res => {
      if(res){
      this.profile = res;
      this.profile.AccountID = res.id;

      this.profileForm.patchValue({
        Name: this.profile?.Name,
        AccountID: this.profile?.AccountID,
        RegistrationNumber: this.profile ? this.profile.RegistrationNumber : '',
        Category: this.profile ? this.categories.find(c => c.id === this.profile.Category.id) : null,
        OwnershipInfo: this.profile ? this.profile.OwnershipInfo : '',
        Address: this.profile ? this.profile.Address : '',
        Website: this.profile ? this.profile.Website : '',
        Mobile: this.profile ? this.profile.Mobile : '',
        Email: this.profile ? this.profile.Email : '',
        Notes: this.profile ? this.profile.Notes : '',
        InformedDataConsent: this.profile ? this.profile.InformedDataConsent : false,
        OwnerID: this.profile ? this.profile.OwnerID : '',
        RegistrationDate: this.profile ? this.profile.RegistrationDate : '',
        Status: this.profile ? this.profile.Status : 'Created',
        About: this.profile ? this.profile.About : '',
      });
      }
      
      this.saving = false;
    });
  }
  async saveProfile(){
    if(this.profileForm.dirty){
      this.saving = true;
      this.businessService
      .saveBusiness({...this.profileForm.value})
      .then((res) => {
        this.businessService.newBusinessEmail = undefined;
        this.openSnackBar('Business saved');
        this.stepper.next();
        this.businessService.getBusinessCount();
        this.businessService.activatedBusiness.next(res);
        this.saving = false;
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.saving = false;
      });
      this.profileForm.markAsPristine();
    } else {
      this.stepper.next();
    }
  }
  getErrorMessage() {
    if (this.profileForm.controls['Name'].hasError('required')) {
      return 'You must enter a value';
    }
  
    return this.profileForm.controls['Name'].hasError('required') ? 'Not a valid name' : '';
  }

  async saveBusinessConsent(event: {business: BusinessConsent, signature: string}){
    this.saving = true;
    console.log(event)
    this.businessService
      .saveBusinessConsent(event.business)
      .then(res => {
        this.openSnackBar('Consent saved');
        this.emitProfile.emit(event.business);
        this.businessService.activatedBusiness.next(res)
        this.saving = false;
        this.stepper.reset();
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.saving = false;
      });
  }


  back(){
    this.stepper.previous();
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }
}




