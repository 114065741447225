import { Injectable } from '@angular/core';
import { ParseClasses } from 'backend/enum/ParseClasses.enum';
import { AVNotification } from 'backend/interfaces/notification.interface';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import * as Parse from "parse";
@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  notifications = new BehaviorSubject<any[]>(null);
  notifications$ = this.notifications.asObservable();
  subscription;
  notificationObjects;
  query = new Parse.Query(ParseClasses.NOTIFICATIONS);

  constructor() { 
  }

  async subscribeToNotifications(){
    this.notifications.next(null);
    this.query.equalTo('userId', Parse.User.current().id);
    this.query.descending('createdAt');  
    this.query.limit(100);
    await this.query.find().then(notifications => {
      this.notificationObjects = new Set(notifications);
      this.notifications.next(this.notificationObjects);
    }).catch(error => {
      alert(`Failed to retrieving objects, with error code: ${error.message}`);
    });
    this.subscription = await this.query.subscribe();
    this.subscription.on('open', message => {
      console.log('trigger open')

      // this.messages.forEach(t => {
      //   if(Parse.User.current().id === t.get('Recipient').id && t.get('read')===false){
      //     console.log(t)
      //     t.set('read', true);
      //     t.save();
      //   }
      // });
    });
    this.subscription.on('create', notification => {
      // this.notificationObjects.forEach(t => {
      //   if(Parse.User.current().id === t.userId && t.get('read')===false){
      //     console.log(t)
      //     t.set('read', true);
      //     t.save();
      //   }
      // });
      this.notificationObjects.add(notification);
      this.notifications.next(this.notificationObjects);
      console.log('trigger create')
    });
    this.subscription.on('update', message => {
      console.log(message)
    });
    this.subscription.on('leave', message => {
      console.log('trigger leave')
    });
    this.subscription.on('delete', message => {
      // this.messages.forEach(t => {
      //   if (t.id === message.id) {
      //     this.messages.delete(t);
      //   }
      // });
      console.log('trigger delete')
    });
  }

  saveNotification(notification: AVNotification): Promise<AVNotification> {
    return Parse.Cloud.run('saveNotification', { notification }).then((result) => {
      return result;
    });
  }

  saveNotificationForBusinessessInCategory(businessIdArray, notification: AVNotification): Promise<AVNotification> {
    return Parse.Cloud.run('saveNotificationForBusinessessInCategory', { businessIdArray, notification }).then((result) => {
      return result;
    });
  }
}
