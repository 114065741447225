import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';
import { CalendarEvent } from 'angular-calendar';
import { GoogleCalendarEvent } from 'backend/interfaces/calendar.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Student } from 'backend/interfaces/student.interface';
import * as moment from "moment";
import { Business, BusinessSearchCriteria } from 'backend/interfaces/business.interface';
import { ParentConsent } from 'backend/interfaces/parent.interface';

@Injectable({
  providedIn: 'root'
})

export class BusinessService {
  businesses = new BehaviorSubject<Business[] | null>(null);
  businesses$ = this.businesses.asObservable();
  businessSearch = new BehaviorSubject<{name:string} | null>(null);
  businessSearch$ = this.businessSearch.asObservable();

  activatedBusiness = new BehaviorSubject<Business>(null);
  activatedBusiness$ = this.activatedBusiness.asObservable();
  activatedDrive = new BehaviorSubject<any[]>(null);
  activatedDrive$ = this.activatedBusiness.asObservable();

  businessCount = new BehaviorSubject<number | null>(null);
  businessCount$ = this.businessCount.asObservable();

  businessRequests = new BehaviorSubject<Business[] | null>(null);
  businessRequests$ = this.businessCount.asObservable();
  uploadToken: string;
  public newBusinessEmail: string;
  constructor(private http: HttpClient) {
  }

  saveBusiness(business: Business): Promise<Business> {
    return Parse.Cloud.run('saveBusiness', { business }).then((result) => {
      return result;
    });
    
  }

  saveBusinessConsent(businessConsent: ParentConsent): Promise<Business> {
    return Parse.Cloud.run('saveBusinessConsent', { businessConsent }).then((result) => {
      return result;
    });
  }


  getBusinesses(displayLimit: number, page: number): Promise<void> {
    return Parse.Cloud.run('getBusinesses', { displayLimit, page }).then((result) => {
      this.businesses.next(result);
    });
  }

  getBusinessById(id: string): Promise<Business> {    
    return Parse.Cloud.run('getBusinessById', { id }).then(res => {
      this.activatedBusiness.next(res)
    });
  }

  getBusinessByName(name: string): Promise<Business> {    
    return Parse.Cloud.run('getBusinessByName', { name }).then(res => {
      this.activatedBusiness.next(res)
    });
  }

  getStudentsByBusinessId(id: string, mainContact: boolean): Promise<Student[]> {
    return Parse.Cloud.run('getStudentsByBusinessId', { id, mainContact });
  }

  getBusinessesByUserId(id: string): Promise<Business[]> {
    return Parse.Cloud.run('getBusinessesByUserId', { id })
  }

  getBusinessByEmail(email: string): Promise<Business> {
    return Parse.Cloud.run('getBusinessByEmail', { email });
  }

  getCalendarBusy(): Promise<any>{
    const from = "2023-01-01T00:00:00.000Z";
    const to = "2023-04-01T00:00:00.000Z";
    return Parse.Cloud.run('getCalendarBusy', { from, to });
  }

  getCalendarEvents(): Promise<any>{
    return Parse.Cloud.run('getCalendarEvents');
  }

  getBusinessCount(): Promise<number>{
    return Parse.Cloud.run('getBusinessCount').then((result) => {
      this.businessCount.next(result);
    });;
  }


  createBooking(event: GoogleCalendarEvent): Promise<any>{
    return Parse.Cloud.run('createBooking', { event });
  }

  updateBooking(event: GoogleCalendarEvent): Promise<any>{
    return Parse.Cloud.run('updateBooking', { event });
  }

  deleteBooking(id: string): Promise<any>{
    return Parse.Cloud.run('deleteBooking', { id });
  }

  searchBusiness(criteria: BusinessSearchCriteria): Promise<any>{
    return Parse.Cloud.run('searchBusiness', { criteria });
  }

  getBusinessRequests(status: string): Promise<any>{
    return Parse.Cloud.run('getBusinessRequests', { status }).then((result) => {
      this.businessRequests.next(result);
    });
  }

  saveProfilePicture(businessId: string, image: any): Promise<any>{
    return Parse.Cloud.run('saveProfilePicture', { businessId, image });
  }

  getBusinessFile(id: string, mimeType: string): Promise<any>{
    return Parse.Cloud.run('getFileById', { id, mimeType })
  }

  getbusinessFiles(fileId: string): Promise<any>{
    return Parse.Cloud.run('getFilesById', { fileId })
  }

  getAlbumList(id: string): Promise<any>{
    return Parse.Cloud.run('getAlbumList', { id })
  }

  getAlbumById(id: string): Promise<any>{
    return Parse.Cloud.run('getAlbumById', { id })
  }

  createDriveFolder(businessId: string): Promise<any>{
    return Parse.Cloud.run('createBusinessDriveFolder', { businessId })
  }

  createAccount(): Promise<any>{
    return Parse.Cloud.run('saveAccount')
  }

  createPhotoAlbum(businessId: string): Promise<any>{
    return Parse.Cloud.run('createPhotoAlbum', { businessId })
  }

  uploadFile(id: string, uploadedFile: any, description: string): Promise<any>{
    return Parse.Cloud.run('uploadFile', { id, uploadedFile, description })
  }

  async uploadPhoto(id: string, uploadedFile: any, description: string): Promise<any>{
    const authToken = await Parse.Cloud.run('getGToken', { });
    let body = uploadedFile.source;
    let newMediaItems = [];

    const reqHeaders = new HttpHeaders({
        Authorization: 'Bearer '+ authToken,
        'Content-type': 'application/octet-stream',
        'X-Goog-Upload-Content-Type': uploadedFile.type,
        'X-Goog-Upload-File-Name': uploadedFile.name,
        'X-Goog-Upload-Protocol': 'raw'
      })
    

      const batchCreateHeaders = new HttpHeaders({
        Authorization: 'Bearer '+ authToken,
        'Content-type': 'application/json'
      })
    
    let uploadToken = await this.http.post(
      'https://photoslibrary.googleapis.com/v1/uploads',
      body, {headers: reqHeaders, responseType: 'text'}).toPromise(); 

      // uploadToken.tap(res => {
    newMediaItems.push(
          {
            description: uploadedFile.description,
            simpleMediaItem: {
              fileName: uploadedFile.name,
              uploadToken: uploadToken
            }
          });

      return this.http.post(
            'https://photoslibrary.googleapis.com/v1/mediaItems:batchCreate',
            { albumId: id, newMediaItems }, {headers: batchCreateHeaders}).toPromise();

    // return Parse.Cloud.run('uploadPhoto', { id, uploadedFile })

  }
}

