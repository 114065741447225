import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocationService } from 'src/app/admin/services/location.service';
import { Location } from 'backend/interfaces/location.interface';
import { Service } from 'backend/interfaces/service.interface';
import { ServicesService } from 'src/app/admin/services/services.service';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {
  saving = false;
  faTrash = faTrash;
  @Input() service: Service = {title: '', description: '', duration: '', note: '', id: '', price: ''};
  @Output() emitService: EventEmitter<any> = new EventEmitter();
  @Output() closeService: EventEmitter<any> = new EventEmitter();
  serviceForm: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder, private servicesService: ServicesService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.serviceForm = this.fb.group({
      title: [this.service ? this.service.title : '', Validators.required],
      description: [this.service ? this.service.description : '', Validators.required],
      duration: [this.service ? this.service.duration : ''],
      note: [this.service ? this.service.note : ''],
      id: [this.service ? this.service.id : ''],
      price: [this.service ? this.service.price : '']

    })
  }

  async saveService(){
    if(this.serviceForm.dirty){
      this.saving = true;
      this.servicesService
      .saveService(this.serviceForm.value)
      .then((res) => {
        this.saving = false;
        this.openSnackBar('Service saved');
        this.servicesService.getServices(10, 0);
        this.servicesService.getServicesCount();
        this.closeService.emit();
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.saving = false;
      });
      this.serviceForm.markAsPristine();
      this.service.title = this.serviceForm.controls['title'].value;
    }else{
      this.closeService.emit();
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

  deleteService(){
    this.saving = true;
    this.servicesService.deleteService(this.service.id).then(res => {
      this.saving = false;
      this.openSnackBar('Service deleted');
      this.servicesService.getServices(10, 0);
      this.servicesService.getServicesCount();
      this.closeService.emit();
    }).catch(err => {
      this.openSnackBar(err);
    })
  }
}
