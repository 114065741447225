import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { DataService } from 'src/app/services/data.service';
import * as Parse from "parse";
import { Router } from '@angular/router';
import { ParentService } from 'src/app/admin/services/parent.service';
@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {
  loader = false;
  email: string;
  errorMessage: string;
  securityForm: FormGroup;
  currentUser: Parse.User;
  passwordVisible = false;
  npasswordVisible = false;
  cpasswordVisible = false;
  constructor(private authService: AuthService, private fb:FormBuilder, private dataService: DataService, private router: Router, private parentService: ParentService) { }

  ngOnInit(): void {

    if(this.router.url.includes('admin')){
      this.parentService.activatedParent$.subscribe(res => {
        this.currentUser = res;
        this.email = res?.Email;
        this.securityForm = this.fb.group({
          pw: ['', Validators.required],
          npw: ['', Validators.required],
          cpw: ['', Validators.required]
        })
      });
    }else{
      this.authService.$User.subscribe(res => {
        this.currentUser = res;
        this.email = res.get('username');
        this.securityForm = this.fb.group({
          pw: ['', Validators.required],
          npw: ['', Validators.required],
          cpw: ['', Validators.required]
        })
      });
    }
  }

  saveSecurityUpdates() {
    const userID = this.router.url.includes('admin') ? this.currentUser.ID : this.currentUser.get('ID')
    this.loader = true;
    if(this.securityForm.controls['npw'].value === this.securityForm.controls['cpw'].value){
    this.dataService.updateUserPassword(userID, this.securityForm.value).then(res => console.log(res));
    }else{
      this.errorMessage = "Make sure your password is correct and that new passwords meet the requirements.";
    }
  }
}
