<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<div class="container scrollable">
    <div [innerHtml]="terms | safeHtml"></div>
</div>
