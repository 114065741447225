
<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
    <div>
        <mat-spinner color="accent"></mat-spinner>
    </div>
</div>
<div [ngStyle]="{'background-color':'#'+adColor}">
<ng-container *ngIf="image1; else placeholder">
  <div class="bm-card d-flex flex-column border-radius-8" [style]="" [ngStyle]="{'background-image':'url'+'('+image1.source +')'}">

  </div>
</ng-container>

<ng-template #placeholder>
  <div class="bm-card d-flex flex-column border-radius-8" [style.backgroundImage]="'../assets/imgUpload.png'">
    <div class="bg-50perc-black p-3 m-3">
      <h1 class="text-white">{{title}}</h1>
      <p class="text-white">{{body}}</p>
    </div>
  </div>
</ng-template>
</div>