import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faDownload, faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { SignaturePadComponent } from '../signature-pad/signature-pad.component';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { FileUpload } from 'backend/interfaces/file-upload';
import { Business } from 'backend/interfaces/business.interface';
import { BusinessService } from 'src/app/admin/services/business.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Parse from 'parse';
@Component({
  selector: 'app-student-consent-form',
  templateUrl: './student-consent-form.component.html',
  styleUrls: ['./student-consent-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StudentConsentFormComponent implements OnInit, AfterViewInit {
  consentForm: UntypedFormGroup;
  faPhone = faPhone;
  faHome = faHome;
  faDownload = faDownload;
  faEnvelope = faEnvelope;
  signature: any;
  signed = false;
  pdf: FileUpload;
  saving = false;

  @ViewChild('signatureElement', {static: false}) signatureElement: ElementRef;
  @ViewChild('Consent', {static: false}) Consent: ElementRef;
  @ViewChild('ConsentContainer', {static: false}) ConsentContainer: ElementRef;
  @Input() business?: Business;
  @Output() emitStudent = new EventEmitter<Object>();
  @Output() emitBack = new EventEmitter<Boolean>();
  panelOpenState = false;
  company = {
    name: 'ADVIBE',
    practitioner: 'Name Surname',
    prNo: '',
    address: {
      number: '',
      street: '',
      suburb: '',
      city: '',
      code: ''
    },
    contactNumber: '',
    email: ''
  }
  dataConsent;
  constructor(private fb: UntypedFormBuilder, public dialog: MatDialog, private businessService: BusinessService) { }

async ngOnInit(): Promise<void> {
  this.businessService.activatedBusiness$.subscribe(async res => {
    if(res){
    this.business = res;
    this.business.id = res.id;
    }
  });
  Parse.Cloud.run('getCustomerConsent').then(async res => {
    if(res){
      this.dataConsent = res.get('customerConsent');
    this.consentForm = this.fb.group({
      InformedDataConsent: [this.business?.InformedDataConsent, Validators.required],
      Document: [this.business && this.business.Document ? this.business.Document: {source: '', name: ''}, Validators.required]});
    }
  })
}

ngAfterViewInit(): void {
}

async saveBusinessConsent(){
  this.saving = true;
  await this.generatePDF();
  setTimeout(() => {
    this.emitStudent.emit({'business': {
      id: this.business.id,
      ID: this.business.OwnerID,
      InformedDataConsent: this.consentForm.controls['InformedDataConsent'].value,
      Document: this.consentForm.controls['Document'].value
    }, 'signature': this.signature});
    this.saving = false;
  }, 4000);
}

openDialog(): void {
  const dialogRef = this.dialog.open(SignaturePadComponent, {
    data: {name: this.business.Name},
    width: '80%',
  });

  dialogRef.afterClosed().subscribe(result => {
    if(result){
      this.signature = result.data;
      this.signatureElement.nativeElement.setAttribute('src', this.signature);
      this.signed = true;
    } else {
      this.signed = false;
    }
  });
}

getErrorMessage() {
  if (this.consentForm.controls['Name'].hasError('required')) {
    return 'You must enter a value';
  }

  return this.consentForm.controls['Name'].hasError('required') ? 'Not a valid name' : '';
}


  async generatePDF(action?: string): Promise<void> {
    const personNameAndSurname = this.business.Name;
    let docDefinition = {
      content: [
        {
          columns: [
            [
              {
                margin: [-40, -40, -40, 0],
                width: 595,
                image: await this.getBase64ImageFromURL('../../../assets/letterhead.png')
              }
            ]
          ]
        },
        {
          margin: [0,10,0,5],
          columns: [
            {
              text: 'Client Details',
              fontSize: 10,
              bold: true,
            }
          ]
        },
        {
          columns: [
          {
            // Student Particulars
            fontSize: 10,
            stack: [
              {
                columns: [
                  {
                    text: 'Name: ', margin: [0,1], width: '25%'
                  },
                  {
                    text: this.business.Name, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'ID Number: ', margin: [0,1], width: '25%'
                  },
                  {
                    text: this.business.OwnerID, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'Mobile: ', margin: [0,1], width: '25%'
                  },
                  {
                    text: this.business.Mobile, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'Email: ', margin: [0,1], width: '25%'
                  },
                  {
                    text: this.business.Email, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'Address: ', margin: [0,1], width: '25%'
                  },
                  {
                    text: this.business.Address, margin: [0,1], width: 'auto'
                  }
                ]
              },
            ],
          }
        ]},
        {
          text: 'Informed Consent',
          fontSize: 10,
          margin: [0, 10, 0, 0],
          bold: true
        },
        {

          stack: [
            {
              text: this.ConsentContainer.nativeElement.innerText, 
              margin: [0,5]
            },
          ],
          fontSize: 10

    },
        { 
          image: this.signature,
          fit: [80,80],
          style: 'sectionHeader'
        },
        {
          text: "Signature"
        }
      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0,15,0,15]          
        }
      }
    };
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    await pdfDocGenerator.getBase64((data) => {
      const pdf = {
        name: this.business.Name+'_Consent'+'.pdf',
        source: data
      };
      setTimeout(() => {
        this.consentForm.controls['Document'].setValue(pdf);
      }, 500);
    })
    // if(action==='view'){
    //   pdfDocGenerator.open(); 
    // }
  }


  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }

  back(){
    this.emitBack.emit(true);
  }
  download() {
    const linkSource = this.business.Document;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource.source;
    downloadLink.target = '_blank';
    downloadLink.download = linkSource.name;
    downloadLink.click();
  }
}