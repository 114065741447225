import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  subscriptionDetails = new BehaviorSubject<any | null>(null);
  subscriptionDetails$ = this.subscriptionDetails.asObservable();
  cancelationRequest = new BehaviorSubject<any | null>(null);
  cancelationRequest$ = this.cancelationRequest.asObservable();
  constructor() { }


  makePayment(paymentForm: any):Promise<any>{
    return Parse.Cloud.run('makePayment', { paymentForm })
  }

  cancelSubscription(userID: string, form: any): Promise<any>{
    return Parse.Cloud.run('cancelSubscription', { userID, form });
  }
  deleteCancelationRequest(userID: string): Promise<any>{
    return Parse.Cloud.run('deleteCancelationRequest', { userID });
  }

  confirmSubscription(id: string): Promise<any>{
    return Parse.Cloud.run('confirmSubscription', { id });
  }

  confirmCancelation(id: string): Promise<any>{
    return Parse.Cloud.run('confirmCancelationRequest', {id});
  }

  removeCancelationRequest(id: string): Promise<any>{
    return Parse.Cloud.run('removeCancelationRequest', {id});
  }
}
