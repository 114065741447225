
<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<div class="p-2 d-flex flex-column justify-content-between h-100">
  <div class="spacer"></div>
  <div class="flex-1">
    <h1 class="mat-display-2 mb-3">Create an Account</h1>
    <p class="mat-subheading-2 mb-5">
      Please complete the form below to get started on your marketing journey.
    </p>

    <form class="user w-100 d-flex flex-column" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="outline">
        <input
          matInput
          type="text"
          formControlName="id"
          class="form-control form-control-user"
          placeholder="ID Number"
        />
        <mat-error *ngIf="loginForm.controls.id.invalid">ID is Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <input
          matInput
          type="text"
          formControlName="name"
          class="form-control form-control-user"
          placeholder="Name"
        />
        <mat-error *ngIf="loginForm.controls.name.invalid">Name is Required</mat-error>
        </mat-form-field>
      <mat-form-field appearance="outline">
        <input
        matInput
          type="text"
          formControlName="surname"
          class="form-control form-control-user"
          placeholder="Surname"
        />
        <mat-error *ngIf="loginForm.controls.surname.invalid">Surname is Required</mat-error>
        </mat-form-field>
      <mat-form-field appearance="outline">
        <input
        matInput
          type="text"
          formControlName="mobile"
          class="form-control form-control-user"
          placeholder="Mobile Number"
        />
        <mat-error *ngIf="loginForm.controls.mobile.invalid">Mobile is Required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
        <input
          matInput
          type="email"
          formControlName="email"
          class="form-control form-control-user"
          placeholder="Email Address"
        />
        <mat-error *ngIf="loginForm.controls.email.invalid">Email is Required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="flex-1">
        <mat-label>Password</mat-label>
        <input matInput [type]="!passwordVisible ? 'password' : 'text'" formControlName="password" name="password" placeholder="Password" id="password" name="password">
        <button type="button" mat-icon-button matSuffix (click)="passwordVisible = !passwordVisible" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordVisible">
          <!-- <fa-icon [icon]="passwordVisible ? faEye : faEyeSlash"></fa-icon>  -->
          <mat-icon>{{passwordVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>

      <div class="col-md-12 d-flex flex-column align-items-center">
        <h2 id="Consent">I accept the <a href="https://www.advibe.co.za/terms-and-conditions" target="_blank">Terms of Service</a>?</h2>
        <mat-radio-group
          aria-labelledby="Consent"
          formControlName="InformedDataConsent"
        >
          <mat-radio-button class="example-radio-button m-3" [value]="true">
            Yes
          </mat-radio-button>
          <mat-radio-button class="example-radio-button m-3" [value]="false">
            No
          </mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="!loginForm.controls.InformedDataConsent.pristine && !loginForm.controls.InformedDataConsent.value">In order for you to use the platform we need your consent.</mat-error>
      </div>
      <button [disabled]="loginForm.invalid || !loginForm.controls.InformedDataConsent.value" type="submit" class="btn btn-primary btn-user btn-block">
        Register Account
      </button>
    </form>
  </div>
  <div class="spacer"></div>
  <div class="mt-3">
    <h5>Already have an account?</h5>

    <hr />
    <div class="mb-4">
      <button type="button" mat-stroked-button routerLink="/login">
        Login!
      </button>
    </div>
  </div>
</div>
