

  <div class="mb-3 mt-5">
    <div class="row m-0 mb-3">
      <div class="d-flex align-items-center col-md-12">
        <div class="border-primary p-2 mb-3 w-100 h-100 relative bg-50perc-white">
          <div
          class="d-flex justify-content-center loader flex-column align-items-center"
          *ngIf="loading"
        >
          <div>
            <mat-spinner color="accent"></mat-spinner>
          </div>
        </div>
        <form
            class="user mb-5"
            [formGroup]="groupForm"
            (ngSubmit)="saveGroup()">
            <div class="photo-container border-radius-5 link mb-4" (click)="uploadFile()">
              <fa-icon
                class="upload-icon text-secondary"
                [icon]="faUpload"
                size="2x"
              ></fa-icon>
              <img
                class="text-center margin-auto link"
                [src]="logo"
                #imagePreview
                alt="image"
              />
              <input
                type="file"
                (change)="onFileSelected($event)"
                #fileInput
                formControlName="logo"
                style="display: none"
              />
            </div>

              <mat-form-field class="w-100 mb-3">
                <input class="m-0" matInput formControlName="name" placeholder="Name" />
              </mat-form-field>

              <mat-form-field class="w-100" appearance="fill">
                <mat-label>Description</mat-label>
                <textarea
                  rows="5"
                  matInput
                  placeholder=""
                  formControlName="description"
                ></textarea>
              </mat-form-field>

              <mat-toolbar class="d-flex justify-content-between">
                <button
                  *ngIf="group?.id"
                  type="button"
                  (click)="openDeleteDialog()"
                  mat-stroked-button
                  color="accent"
                >
                  Delete
                </button>
                <button type="submit" mat-raised-button color="primary">Save</button>
              </mat-toolbar>
            </form>
        </div>
      </div>
      <!-- <div class="col-md-12 d-flex flex-column">
        <h3>Followers</h3>
        <div class="d-flex flex-wrap">
          <button class="mr-2 mb-2" type="button" mat-stroked-button color="accent" *ngFor="let s of students" (click)="openStudent(s.ID)"> <fa-icon [icon]="faUser"></fa-icon>  {{s.Name}} {{s.Surname}}</button>
        </div>
        <hr/>
        <h3>Businesses</h3>
        <div class="d-flex flex-wrap">
          <button class="mr-2 mb-2" type="button" mat-stroked-button color="accent" *ngFor="let t of teachers" (click)="openTeacher(t.id)"> <fa-icon [icon]="faUser"></fa-icon>  {{t.title}} {{t.name}} {{t.surname}}</button>
        </div>
      </div> -->

    </div>
  </div>

