import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private dataService: DataService) { }
  categoryCount;
  ngOnInit(): void {
    this.dataService.groupCount$.subscribe(res => {
      this.categoryCount = res;
    })
  }

}
