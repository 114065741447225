import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faChevronCircleLeft, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';


interface DialogData {
  ads: any[]
}

@Component({
  selector: 'app-ad-viewer-dialog',
  templateUrl: './ad-viewer-dialog.component.html',
  styleUrls: ['./ad-viewer-dialog.component.scss']
})

export class AdViewerDialogComponent implements OnInit {
  faPrev = faChevronLeft;
  faNext = faChevronRight;
  constructor(public dialogRef: MatDialogRef<AdViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    console.log(this.data)
  }

}
