<div class="p-3">
    <h1 mat-dialog-title>{{question}}</h1>
    <div mat-dialog-content>
      <p>Canceling your subscription means that all your businesses and ads will be removed from ADVIBE. By submitting you agree that a cancelation request must be opened on your account and processed within 10 days.</p>

    </div>


    <form [formGroup]="cancelationForm" (ngSubmit)="submitCancelation()" class="d-flex flex-column">
        <mat-form-field>
            <input matInput name="reason" placeholder="Reason for cancelation" formControlName="reason"/>
        </mat-form-field>
    
        <mat-toolbar class="mt-5 justify-content-between align-items-center">
            <button mat-button (click)="onNoClick()">No, wait</button>
        <button [disabled]="cancelationForm.invalid" type="submit" mat-raised-button color="primary" class="text-white">Submit cancelation</button>
        </mat-toolbar>
        </form>
    

    </div>