import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentService } from 'src/app/services/payment.service';
import { Md5 } from 'md5-typescript';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/auth/auth.service';
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  paymentForm: FormGroup;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/string'
    })
  };
  @ViewChild('submitFormForPayment') submitFormForPayment: ElementRef;
  orderForm: FormGroup;
  payfastURLSandbox = 'https://sandbox.payfast.co.za​/eng/process';
  payfastURL = 'https://www.payfast.co.za/eng/process';
  // sandbox
  // merchant_id: '10034791',
  // merchant_key: 'n3ocmcgzphz3j',

  // live
  // merchant_id: '25041268',
  // merchant_key: 'h0xcvjn9uig1q',

  payfastObject = {
    merchant_id: '25041268',
    merchant_key: 'h0xcvjn9uig1q',
    return_url: 'https://www.advibe.co.za/user/paymentSuccess',
    cancel_url: 'https://www.advibe.co.za/user/paymentCancelled',
    notify_url: 'https://www.advibe.co.za/user/dashboard',
    subscription_type:1,
    name_first: '',
    name_last: '',
    email_address: '',
    cell_number: '',
    m_payment_id: '',
    amount: '',
    billing_date: '',
    item_name: 'Advibe',
    item_description: 'A product',
    custom_int1: '2',
    custom_str1: 'Extra order information',
    email_confirmation: '1',
    confirmation_address: ''
  };
  payfastSubmissionString = '';
  payfastSignature = '';
  wizardStep = 1;
  faCheckCircle = faCheckCircle;
  user;
  constructor(private http: HttpClient, private fb: FormBuilder, private paymentService: PaymentService, private datePipe: DatePipe, private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.$User.subscribe(async (res) => {
      if (res) {
        this.user = res;
      }
    });
    this.paymentForm = this.fb.group({
      amount: ['', Validators.required],
      item_name: ['', Validators.required]
    })

    this.orderForm = this.fb.group({
      name: [this.user ? this.user.get('Name') : '', Validators.required],
      surname: [this.user ? this.user.get('Surname') : '', Validators.required],
      billingDate: [{ value: this.datePipe.transform(new Date, 'yyyy-MM-dd'), disabled: true }],
      email: [this.user ? this.user.get('email') : '', Validators.email],
      contactNumber: [this.user ? this.user.get('Mobile') : '', Validators.required],
      cycles: ['1', Validators.required],
      address: [this.user ? this.user.get('Address') : '', Validators.required],
      package: [this.user && this.user.get('email') === 'info@advibe.co.za' ? '10' : '2250', Validators.required],
      dateTime: ['']
    });
  }

  nextStep(): void {
    this.wizardStep += 1;
    window.scroll(0, 0);
  }

  previousStep(): void {
    this.wizardStep -= 1;
    window.scroll(0, 0);
  }
  makePayment(): void {
    this.payfastObject = {...this.orderForm.value};
    this.paymentService.makePayment(this.payfastObject).then(res => {
    }).catch(err => console.log(err))
  }

  async placeOrder() {
    this.orderForm.controls.dateTime.setValue(new Date());
    // this.paymentService.makePayment(this.orderForm.value).then(res => {
    this.payfastObject.name_first = this.orderForm.controls.name.value;
    this.payfastObject.name_last = this.orderForm.controls.surname.value;
    this.payfastObject.email_address = this.orderForm.controls.email.value;
    this.payfastObject.billing_date = this.orderForm.controls.billingDate.value;
    this.payfastObject.cell_number = this.orderForm.controls.contactNumber.value;
    this.payfastObject.amount = this.orderForm.controls.package.value === 1 ? '1000' : '2250';
    this.payfastSignature = await this.generateSignature();
    this.submitFormForPayment.nativeElement.click();
    
    this.paymentService.subscriptionDetails.next(this.orderForm.value);
    this.makePayment();
  }

  generateSignature(): string {
    let result = '';
    for (const property in this.payfastObject) {
      result += `${property}=${this.payfastObject[property]}&`;
    }
    this.payfastSubmissionString = result;
    result += 'passphrase=aDV1b3PayMent';
    return Md5.init(result);
  }

  selectSubscriptionPackage(subscriptionOption: number): void {
    if (subscriptionOption === 2) {
      this.orderForm.controls.package.setValue('2250');
    } else {
      this.orderForm.controls.package.setValue('1000');
    }
  }

  async generatePaymentIdentifier(pfParamString){

    const reqHeaders = new HttpHeaders({

    })
    return await this.http.post(
      this.payfastURLSandbox,
      pfParamString, {headers: reqHeaders, responseType: 'json'}).toPromise(); 
  };

}
