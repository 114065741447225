<ng-container *ngIf='business; else noBusiness'>


<mat-tab-group>
    <mat-tab label="Business Profile">
        <div class="col-md-12 m-0 border-radius-5 justify-content-center p-3 border-primary relative">
              <div class="d-flex flex-wrap mb-3 justify-content-center">
                <!-- <div class="d-flex align-items-center"> -->
                  <div *ngIf="!business.ProfilePicture || business.ProfilePicture?.source === ''" class="initials text-white link" (click)="openProfilePictureDialog()">
                    <fa-icon [icon]="faUser" class="text-white"></fa-icon> 
                  </div>
                  <div *ngIf="business.ProfilePicture && business.ProfilePicture?.source !== ''" class="profile-pic-initials lg link" (click)="openProfilePictureDialog(business.ProfilePicture.source)">
                    <img #profilePic class="img-fluid" [src]="business.ProfilePicture.source"/>
                  </div>
                  <div class="m-3 d-flex justify-content-center flex-column">
                    <h2 class="m-0">{{business.Name}}</h2>
                  </div>
                <!-- </div> -->
              </div>
          </div>
        <app-business-profile [profile]="business"></app-business-profile>
    </mat-tab>
    <mat-tab label="Ads">
        <h2 class="mt-3 mb-0">Ad Settings</h2>
        <p class="mb-3">Configure ad preferences, targeting options, and budget limits.</p>
        <app-ads></app-ads>
    </mat-tab>
    <mat-tab label="Analytics">
        <h2>Analytics</h2>
        <p>See performance metrics.</p>

        <h2>Favorited By</h2>
        <p>List of customers that added the business to their favorites.</p>

        <h2>Ads</h2>
        <p>Opened: </p>
        <p>Liked: </p>

    </mat-tab>
    <mat-tab label="Chats">
        <h2>Chats</h2>
        <p>View direct chats with customers.</p>

        <app-chat [isBusiness]="true"></app-chat>
    </mat-tab>

</mat-tab-group>
</ng-container>
<ng-template #noBusiness>
    <div class="col-md-12 h-100 d-flex justify-content-center align-items-center flex-column">
        <h1 class="text-center">Select one of your businesses in the panel to the left or add a new business</h1>
        </div>
</ng-template>