
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  title: string;
  question: string;
  result: boolean;
}


@Component({
  selector: 'app-cancel-subscription-dialog',
  templateUrl: './cancel-subscription-dialog.component.html',
  styleUrls: ['./cancel-subscription-dialog.component.scss']
})
export class CancelSubscriptionDialogComponent implements OnInit {
  title;
  question;
  cancelationForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<CancelSubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.question = this.data.question;
    this.cancelationForm = this.fb.group({
      reason: ['', Validators.required]
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submitCancelation(){
    this.dialogRef.close(this.cancelationForm.value)
  }
}
