import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faImage, faPlus, faTrash, faUndo, faUser } from '@fortawesome/free-solid-svg-icons';
import { Ad } from 'backend/interfaces/ad.interface';
import { Business } from 'backend/interfaces/business.interface';
import { FileUpload } from 'backend/interfaces/file-upload';
import { BusinessDataService } from 'src/app/business/services/data.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SideNavService, SidenavActions } from 'src/app/admin/services/side-nav.service';


@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.scss']
})
export class AdsComponent implements OnInit {
  @Output() create: EventEmitter<any> = new EventEmitter();
  @Output() open: EventEmitter<any> = new EventEmitter();
  uploadedImage1: FileUpload;
  uploadedImage2: FileUpload;
  uploadedImage3: FileUpload;
  faImage = faImage;
  faUndo = faUndo;
  faPlus = faPlus;
  faBin = faTrash;
  businessess = [];
  @ViewChild('fileInput1') fileInput1: ElementRef;
  @ViewChild('fileInput2') fileInput2: ElementRef;
  @ViewChild('fileInput3') fileInput3: ElementRef;
  @ViewChild('Image1Display') Image1Display: ElementRef;
  @ViewChild('Image2Display') Image2Display: ElementRef;
  @ViewChild('Image3Display') Image3Display: ElementRef;
  business: Business;
  ads: any[];
  constructor(private dataService: BusinessDataService, public dialog: MatDialog, private sideNavService: SideNavService) { }
  loading = false;
  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.dataService.business$.subscribe(res => {
      if(res){
        this.business = res;
      }
    });
      await this.dataService.businesses$.subscribe(res => {
        if(res){
        this.businessess = res;
        }
      });
      await this.dataService.ads$.subscribe(res => {
        if(res){
          this.ads = res;
        }
      });
      this.loading = false;

  }






  async deleteAd(adId: string){
    this.loading = true;
    await this.dataService.deleteAd(adId);
    await this.dataService.getAdsByBusinessId(this.business.id).then(res => {
      if(res){
        if(!this.ads)
          this.ads = [];
        this.ads = res;
        }
        this.loading = false;
    })
  }

  openDeleteDialog(id: string): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {title: 'Delete', question: 'Are you sure you want to delete this item?', id}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        if(result){
          this.deleteAd(id);
        }else{
          this.dialog.closeAll();
        }
      } else {
        this.dialog.closeAll();
      }
    });
  }

  async reloadAds(){
    this.loading = true;
    await this.dataService.getAdsByBusinessId(this.business.id)
    this.loading = false;
  }

  createAd(){
    this.dataService.activeAd.next(null);
    this.sideNavService.open(SidenavActions.NewAd);
  }

  openAd(ad: Ad){
    console.log(ad)
    this.dataService.activeAd.next(ad);
    this.sideNavService.open(SidenavActions.UpdateAd)
  }

}
