<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>

<div class="row bg-black m-0 p-3">
    <div class="col-md-12 p-3">
      <h1 class="text-center text-white">
        <span class="mat-display-2 text-primary">Find your trusted service provider</span> <br>Your Exclusive Gateway to Visibility and Discovery across {{categoryCount}} Categories!
      </h1>
      <div class="col-md-12" style="max-width:850px;margin:auto;">
        <form [formGroup]="searchCategoryForm" (ngSubmit)="search()" class="d-flex">
            <div appearance="filled" class="d-flex align-items-center flex-1 bg-light p-2 ml-3 mr-3 border-radius-5">
                <fa-icon dis matPrefix size="2x" [icon]="faSearch"></fa-icon>
                <input placeholder="Search" class="searchInput mat-display-1 mb-0 ml-3"  name="searchPhrase" formControlName="searchPhrase"/>
            </div>
            <button type="submit" mat-raised-button color="primary" class="d-flex align-items-center">SEARCH</button>
        </form>

        <div class="d-flex justify-content-center p-3 align-items-center" *ngIf="searchResult">
            <p class="text-white"> results for {{searchPhrase}} </p>
            <button type="button" (click)="clearSearch()" mat-raised-button color="default" class="ml-3 d-flex align-items-center">Clear search</button>
        </div>
    </div>
    </div>
    </div>
    <ng-container *ngIf="!categories || categories.length < 1">
    <div class="row bg-black m-0">
      <div class="col-md-4 mb-4 col-sm-6 col-xs-12 p-3 mb-3">
        <div class="p-3">
            <app-ghost-rect></app-ghost-rect>
        </div>
      </div>
      <div class="col-md-4 mb-4 col-sm-6 col-xs-12 p-3">
        <div class="p-3">
            <app-ghost-rect></app-ghost-rect>
        </div>
      </div>
      <div class="col-md-4 mb-4 col-sm-6 col-xs-12 p-3">
        <div class="p-3">
            <app-ghost-rect></app-ghost-rect>
        </div>
      </div>
      <div class="col-md-4 mb-4 col-sm-6 col-xs-12 p-3">
        <div class="p-3">
            <app-ghost-rect></app-ghost-rect>
        </div>
      </div>
      <div class="col-md-4 mb-4 col-sm-6 col-xs-12 p-3">
        <div class="p-3">
            <app-ghost-rect></app-ghost-rect>
        </div>
      </div>
      <div class="col-md-4 mb-4 col-sm-6 col-xs-12 p-3">
        <div class="p-3">
            <app-ghost-rect></app-ghost-rect>
        </div>
      </div>
    </div>
</ng-container>
<ng-container *ngIf="categories && categories.length > 0 && !searchResult">
        <div class="row bg-black m-0">
      <div *ngFor="let category of categories" class="col-md-4 mb-4 col-sm-6 col-xs-12 p-3">
        <app-flip-card (emitOpen)="open($event)" (emitFollow)="goTo($event)" [category]="category"></app-flip-card>
      </div>
    </div>
      </ng-container>

      <ng-container class="d-flex" *ngIf="searchResult">

        <div class="row bg-black m-0">
            <div *ngFor="let category of searchResult" class="col-md-4 mb-4 col-sm-6 col-xs-12 p-3">
              <app-flip-card (emitOpen)="open($event)" (emitFollow)="goTo($event)" [category]="category"></app-flip-card>
            </div>
          </div>
    </ng-container>
      
