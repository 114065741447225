import { Injectable } from '@angular/core';
import { Group, GroupSearchCriteria } from 'backend/interfaces/group.interface';
import { Student } from 'backend/interfaces/student.interface';


import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  allGroups = new BehaviorSubject<Group[] | null>(null);
  allGroups$ = this.allGroups.asObservable();
  groups = new BehaviorSubject<Group[] | null>(null);
  groups$ = this.groups.asObservable();
  groupCount = new BehaviorSubject<number | null>(null);
  groupCount$ = this.groupCount.asObservable();
  activeSearch = new BehaviorSubject<string | null>(null);
  activeSearch$ = this.activeSearch.asObservable();
  openGroup = new BehaviorSubject<Group | null>(null);
  openGroup$ = this.openGroup.asObservable();
  constructor() {}

  saveGroup(group: Group): Promise<Group> {
    return Parse.Cloud.run('saveGroup', { group }).then((result) => {
      return result;
    });
  }

  getGroups(displayLimit?: number, page?: number): Promise<void> {
    return Parse.Cloud.run('getGroups', { displayLimit, page }).then((result) => {
      this.groups.next(result);
    });
  }


  getAllGroups(displayLimit?: number, page?: number): Promise<void> {
    return Parse.Cloud.run('getAllGroups', { displayLimit, page }).then((result) => {
      this.allGroups.next(result);
    });
  }


  getGroupById(id: string): Promise<Group> {
    return Parse.Cloud.run('getGroupById', { id });
  }

  getStudentsByGroup(group: string): Promise<Student[]> {
    return Parse.Cloud.run('getStudentsByGroup', { group }).then((result) => {
      this.openGroup.next(result);
    });
  }

  getGroupCount(): Promise<number>{
    return Parse.Cloud.run('getGroupCount').then((result) => {
      this.groupCount.next(result);
    });
  }

  deleteGroup(id: string): Promise<any>{
    return Parse.Cloud.run('deleteGroup', { id });
  }

  searchGroup(criteria: GroupSearchCriteria): Promise<any>{
    return Parse.Cloud.run('searchGroup', { criteria });
  }

  getGroupBusinessCount(categoryId: string): Promise<number>{
    return Parse.Cloud.run('getGroupBusinessCount', {categoryId}).then((result) => {
      this.groupCount.next(result);
    });
  }

}