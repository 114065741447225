import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { Ad } from 'backend/interfaces/ad.interface';
import { Business } from 'backend/interfaces/business.interface';
import { FileUpload } from 'backend/interfaces/file-upload';
import { SideNavService } from 'src/app/admin/services/side-nav.service';
import { BusinessDataService } from 'src/app/business/services/data.service';

@Component({
  selector: 'app-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.scss']
})
export class AdComponent implements OnInit {
  loading = false;
  uploadedImage1: FileUpload;
  uploadedImage2: FileUpload;
  uploadedImage3: FileUpload;
  faImage = faImage;
  businessess = [];
  @Input('image1') image1: any;
  @Input('title') title: any;
  @Input('body') body: any;
  @Input('adColor') adColor: any;
  @ViewChild('fileInput1') fileInput1: ElementRef;
  @ViewChild('fileInput2') fileInput2: ElementRef;
  @ViewChild('fileInput3') fileInput3: ElementRef;
  @ViewChild('Image1Display') Image1Display: ElementRef;
  @ViewChild('Image2Display') Image2Display: ElementRef;
  @ViewChild('Image3Display') Image3Display: ElementRef;
  business: Business;
  ads: any[];
  adForm: FormGroup;
  constructor(private fb: FormBuilder, private dataService: BusinessDataService, private sideNavService: SideNavService) { }

  async ngOnInit(): Promise<void> {

  }
}
